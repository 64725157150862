import { FORM_NAMES } from "../../../../utils/constants";
import * as Yup from "yup";
import { RatingValue } from "../../../../utils/types";

export const basicIndicatorsValidationSchema = Yup.object({
  [FORM_NAMES.OVERALL_JUROR_RATINGS]: Yup.number().optional(),
  [FORM_NAMES.OVERALL_JUROR_RATINGS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY]: Yup.number().optional(),
  [FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS]: Yup.number().optional(),
  [FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ATTITUDE_TOWARD_CASE]: Yup.number().optional(),
  [FORM_NAMES.ATTITUDE_TOWARD_CASE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE]: Yup.number().optional(),
  [FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.COMMUNICATION_SKILLS]: Yup.number().optional(),
  [FORM_NAMES.COMMUNICATION_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT]: Yup.number().optional(),
  [FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL]: Yup.string().optional()
});

export const basicIndicatorsInitialValues = {
  [FORM_NAMES.OVERALL_JUROR_RATINGS]: "",
  [FORM_NAMES.OVERALL_JUROR_RATINGS_COMMENT]: "",
  [FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY]: "",
  [FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY_COMMENT]: "",
  [FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS]: "",
  [FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS_COMMENT]: "",
  [FORM_NAMES.ATTITUDE_TOWARD_CASE]: "",
  [FORM_NAMES.ATTITUDE_TOWARD_CASE_COMMENT]: "",
  [FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE]: "",
  [FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE_COMMENT]: "",
  [FORM_NAMES.COMMUNICATION_SKILLS]: "",
  [FORM_NAMES.COMMUNICATION_SKILLS_COMMENT]: "",
  [FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT]: "",
  [FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT_COMMENT]: "",
  [FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL]: ""
};

export const bodyLangInitialValues = {
  [FORM_NAMES.POSTURE]: 0,
  [FORM_NAMES.POSTURE_COMMENT]: "",
  [FORM_NAMES.EYE_CONTACT]: 0,
  [FORM_NAMES.EYE_CONTACT_COMMENT]: "",
  [FORM_NAMES.ACTIVE_LISTENING]: 0,
  [FORM_NAMES.ACTIVE_LISTENING_COMMENT]: "",
  [FORM_NAMES.EMOTIONAL_DISPLAYS]: 0,
  [FORM_NAMES.EMOTIONAL_DISPLAYS_COMMENT]: "",
  [FORM_NAMES.COLLABORATIVE_BEHAVIOR]: 0,
  [FORM_NAMES.COLLABORATIVE_BEHAVIOR_COMMENT]: "",
  [FORM_NAMES.CONFIDENT_POSTURE]: 0,
  [FORM_NAMES.CONFIDENT_POSTURE_COMMENT]: "",
  [FORM_NAMES.ADAPTATION]: 0,
  [FORM_NAMES.ADAPTATION_COMMENT]: "",
  [FORM_NAMES.GESTURES]: 0,
  [FORM_NAMES.GESTURES_COMMENT]: "",
  [FORM_NAMES.FACIAL_EXPRESSIONS]: 0,
  [FORM_NAMES.FACIAL_EXPRESSIONS_COMMENT]: "",
  [FORM_NAMES.DISTRACTED_BEHAVIOR]: 0,
  [FORM_NAMES.DISTRACTED_BEHAVIOR_COMMENT]: "",
  [FORM_NAMES.BODY_TENSION]: 0,
  [FORM_NAMES.BODY_TENSION_COMMENT]: "",
  [FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR]: 0,
  [FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR_COMMENT]: "",
  [FORM_NAMES.ASSERTIVE_BEHAVIOR]: 0,
  [FORM_NAMES.ASSERTIVE_BEHAVIOR_COMMENT]: "",
  [FORM_NAMES.FLEXIBILITY]: 0,
  [FORM_NAMES.FLEXIBILITY_COMMENT]: "",
  [FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL]: ""
};

// Validation Schema for Formik
export const bodyLangValidationSchema = Yup.object({
  [FORM_NAMES.POSTURE]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.POSTURE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EYE_CONTACT]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.EYE_CONTACT_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ACTIVE_LISTENING]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.ACTIVE_LISTENING_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EMOTIONAL_DISPLAYS]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.EMOTIONAL_DISPLAYS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.COLLABORATIVE_BEHAVIOR]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.COLLABORATIVE_BEHAVIOR_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CONFIDENT_POSTURE]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.CONFIDENT_POSTURE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADAPTATION]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.ADAPTATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.GESTURES]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.GESTURES_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.FACIAL_EXPRESSIONS]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.FACIAL_EXPRESSIONS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.DISTRACTED_BEHAVIOR]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.DISTRACTED_BEHAVIOR_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.BODY_TENSION]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.BODY_TENSION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.DOMINANT_PASSIVE_BEHAVIOR_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ASSERTIVE_BEHAVIOR]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.ASSERTIVE_BEHAVIOR_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.FLEXIBILITY]: Yup.number().min(0).max(5).optional(),
  [FORM_NAMES.FLEXIBILITY_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL]: Yup.string().optional()
});

export const emotionalIntelligenceInitialValues = {
  [FORM_NAMES.EMOTIONAL_AWARENESS]: 0,
  [FORM_NAMES.EMOTIONAL_AWARENESS_COMMENT]: "",
  [FORM_NAMES.EMOTIONAL_REGULATION]: 0,
  [FORM_NAMES.EMOTIONAL_REGULATION_COMMENT]: "",
  [FORM_NAMES.SOCIAL_SKILLS]: 0,
  [FORM_NAMES.SOCIAL_SKILLS_COMMENT]: "",
  [FORM_NAMES.CONFLICT_RESOLUTION]: 0,
  [FORM_NAMES.CONFLICT_RESOLUTION_COMMENT]: "",
  [FORM_NAMES.ADAPTABILITY]: 0,
  [FORM_NAMES.ADAPTABILITY_COMMENT]: "",
  [FORM_NAMES.DECISION_MAKING]: 0,
  [FORM_NAMES.DECISION_MAKING_COMMENT]: "",
  [FORM_NAMES.EMOTIONAL_INFLUENCE]: 0,
  [FORM_NAMES.EMOTIONAL_INFLUENCE_COMMENT]: "",
  [FORM_NAMES.EMPATHY]: 0,
  [FORM_NAMES.EMPATHY_COMMENT]: "",
  [FORM_NAMES.EMOTIONAL_EXPRESSION]: 0,
  [FORM_NAMES.EMOTIONAL_EXPRESSION_COMMENT]: "",
  [FORM_NAMES.CONFLICT_AVOIDANCE]: 0,
  [FORM_NAMES.CONFLICT_AVOIDANCE_COMMENT]: "",
  [FORM_NAMES.STRESS_MANAGEMENT]: 0,
  [FORM_NAMES.STRESS_MANAGEMENT_COMMENT]: "",
  [FORM_NAMES.SELF_MOTIVATION]: 0,
  [FORM_NAMES.SELF_MOTIVATION_COMMENT]: ""
};

export const emotionalIntelligenceValidationSchema = Yup.object({
  [FORM_NAMES.EMOTIONAL_AWARENESS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EMOTIONAL_AWARENESS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EMOTIONAL_REGULATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EMOTIONAL_REGULATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.SOCIAL_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.SOCIAL_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CONFLICT_RESOLUTION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CONFLICT_RESOLUTION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADAPTABILITY]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ADAPTABILITY_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.DECISION_MAKING]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.DECISION_MAKING_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EMOTIONAL_INFLUENCE]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EMOTIONAL_INFLUENCE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EMPATHY]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EMPATHY_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EMOTIONAL_EXPRESSION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EMOTIONAL_EXPRESSION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CONFLICT_AVOIDANCE]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CONFLICT_AVOIDANCE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.STRESS_MANAGEMENT]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.STRESS_MANAGEMENT_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.SELF_MOTIVATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.SELF_MOTIVATION_COMMENT]: Yup.string().optional()
});

export const communicationSkillsInitialValues = {
  [FORM_NAMES.CLARITY_OF_EXPRESSION]: 0,
  [FORM_NAMES.CLARITY_OF_EXPRESSION_COMMENT]: "",
  [FORM_NAMES.ACTIVE_LISTENING]: 0,
  [FORM_NAMES.ACTIVE_LISTENING_COMMENT]: "",
  [FORM_NAMES.RESPECTFUL_COMMUNICATION]: 0,
  [FORM_NAMES.RESPECTFUL_COMMUNICATION_COMMENT]: "",
  [FORM_NAMES.PERSUASIVENESS]: 0,
  [FORM_NAMES.PERSUASIVENESS_COMMENT]: "",
  [FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION]: 0,
  [FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION_COMMENT]: "",
  [FORM_NAMES.ARTICULATION]: 0,
  [FORM_NAMES.ARTICULATION_COMMENT]: "",
  [FORM_NAMES.NON_VERBAL_COMMUNICATION]: 0,
  [FORM_NAMES.NON_VERBAL_COMMUNICATION_COMMENT]: "",
  [FORM_NAMES.CONFLICT_RESOLUTION_SKILLS]: 0,
  [FORM_NAMES.CONFLICT_RESOLUTION_SKILLS_COMMENT]: "",
  [FORM_NAMES.QUESTIONING_SKILLS]: 0,
  [FORM_NAMES.QUESTIONING_SKILLS_COMMENT]: "",
  [FORM_NAMES.NOTE_TAKING_SKILLS]: 0,
  [FORM_NAMES.NOTE_TAKING_SKILLS_COMMENT]: ""
};

export const communicationSkillsValidationSchema = Yup.object({
  [FORM_NAMES.CLARITY_OF_EXPRESSION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CLARITY_OF_EXPRESSION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ACTIVE_LISTENING]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ACTIVE_LISTENING_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.RESPECTFUL_COMMUNICATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.RESPECTFUL_COMMUNICATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.PERSUASIVENESS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.PERSUASIVENESS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ARTICULATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ARTICULATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.NON_VERBAL_COMMUNICATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.NON_VERBAL_COMMUNICATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CONFLICT_RESOLUTION_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CONFLICT_RESOLUTION_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.QUESTIONING_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.QUESTIONING_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.NOTE_TAKING_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.NOTE_TAKING_SKILLS_COMMENT]: Yup.string().optional()
});
