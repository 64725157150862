import * as Yup from "yup";
import { CommunicationPreferencesFormValueProps } from "../../../../../utils/types";

export const validationSchema = Yup.object().shape({
  preferredLanguage: Yup.string().optional(),
  email: Yup.boolean().optional(),
  phoneCalls: Yup.boolean().optional(),
  faceToFaceMeetings: Yup.boolean().optional(),
  videoConferencing: Yup.boolean().optional(),
  microsoftTeams: Yup.boolean().optional(),
  googleHangouts: Yup.boolean().optional(),
  whatsapp: Yup.boolean().optional(),
  telegram: Yup.boolean().optional(),
  skype: Yup.boolean().optional(),
  facebookMessenger: Yup.boolean().optional(),
  appleiMessage: Yup.boolean().optional(),
  instagramDirect: Yup.boolean().optional(),
  signal: Yup.boolean().optional(),
  snapchat: Yup.boolean().optional()
});

export const initialValues: CommunicationPreferencesFormValueProps = {
  preferredLanguage: "",
  email: false,
  phoneCalls: false,
  faceToFaceMeetings: false,
  videoConferencing: false,
  microsoftTeams: false,
  googleHangouts: false,
  whatsapp: false,
  telegram: false,
  skype: false,
  facebookMessenger: false,
  appleiMessage: false,
  instagramDirect: false,
  signal: false,
  snapchat: false
};
