import React from "react";
import { WrapperComponentPropTypes } from "../../utils/types";
import "./styles.wrappercomponent.scss";

/**
 * A wrapper component that applies styling to its children.
 * It accepts all standard HTML div attributes and passes them to the wrapper `div` element.
 *
 * @component
 * @example
 * <WrapperComponent className="custom-class" style={{ padding: '10px' }}>
 *   <p>Content goes here</p>
 * </WrapperComponent>
 *
 * @param {WrapperComponentPropTypes} props - The properties for the wrapper component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the wrapper component.
 * @param {string} [props.title] - Optional title to display above the content.
 * @param {string} [props.buttonText] - Optional text to display inside a button.
 * @returns {JSX.Element} The rendered wrapper component.
 */
export const WrapperComponent: React.FC<WrapperComponentPropTypes> = ({
  title,
  children,
  onClick,
  customClass,
  ...props
}) => {
  return (
    <div className={`wrapper-component ${customClass || ""}`} {...props}>
      {title && (
        <div className='header-section'>
          <span className='heading'>{title}</span>
          {props.buttonText && (
            <button className='btn text-btn' onClick={onClick}>
              {props.buttonText}
            </button>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default WrapperComponent;
