import React from "react";
import { JSXIconProps } from "../../utils/types";

export const LinkedInSVG = (props: JSXIconProps) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width={28}
      height={28}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={28} height={28} rx={14} fill='#2687BE' />
      <path d='M10.125 20H7.21875V10.6562H10.125V20ZM8.65625 9.40625C7.75 9.40625 7 8.625 7 7.6875C7 6.40625 8.375 5.59375 9.5 6.25C10.0312 6.53125 10.3438 7.09375 10.3438 7.6875C10.3438 8.625 9.59375 9.40625 8.65625 9.40625ZM20.9688 20H18.0938V15.4688C18.0938 14.375 18.0625 13 16.5625 13C15.0625 13 14.8438 14.1562 14.8438 15.375V20H11.9375V10.6562H14.7188V11.9375H14.75C15.1562 11.2188 16.0938 10.4375 17.5 10.4375C20.4375 10.4375 21 12.375 21 14.875V20H20.9688Z' />
    </svg>
  );
};
