import * as Yup from "yup";
import { NewCaseFormValues } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  caseName: Yup.string().optional(),
  courtCaseNumber: Yup.number().optional(),
  clientName: Yup.string().optional(),
  caseStatus: Yup.string().optional(),
  courtJurisdiction: Yup.string().optional(),
  caseType: Yup.number().optional(),
  conflictCheck: Yup.boolean().optional(),
  trialStartDate: Yup.date().optional(),
  filingDate: Yup.date().optional(),
  hearingDates: Yup.array().of(Yup.date().optional()),
  injuries: Yup.boolean().optional(),
  dateOfIncident: Yup.date().optional(),
  defendantName: Yup.string().optional(),
  opposingCounselsName: Yup.string().optional(),
  existingCaseOrClient: Yup.string().optional(),
  arrestDate: Yup.date().optional(),
  additionalInformation: Yup.string().optional(),
  discoveryDeadline: Yup.date().optional()
});

export const initialValues: NewCaseFormValues = {
  caseName: "",
  courtCaseNumber: "",
  clientName: "",
  caseStatus: "",
  courtJurisdiction: "",
  caseType: "",
  conflictCheck: false,
  trialStartDate: null,
  filingDate: null,
  hearingDates: [null],
  injuries: false,
  dateOfIncident: null,
  defendantName: "",
  opposingCounselsName: "",
  existingCaseOrClient: "",
  arrestDate: null,
  additionalInformation: "",
  discoveryDeadline: null
};
