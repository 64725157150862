import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Progress } from "reactstrap";
import { JuryProfileGenerationModalProps } from "../../utils/types";
import { AiSearch } from "../../assets/jsxIcons";
import ActionButton from "../ActionButton";
import { BUTTON_TITLE } from "../../utils/constants";
import "./styles.juryprofilegenerationmodal.scss";

export default function JuryProfileGenerationModal({ showModal, onClose, size }: JuryProfileGenerationModalProps) {
  const [timeLeft, setTimeLeft] = useState(30); // Initialize timer to 30 seconds

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showModal) {
      // Reset timer when modal opens
      setTimeLeft(30);
      timer = setInterval(() => {
        setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }
    // Cleanup: clear interval when modal closes
    return () => {
      clearInterval(timer);
    };
  }, [showModal]);

  // Close modal when the timer reaches zero
  useEffect(() => {
    if (timeLeft === 0 && onClose) {
      onClose();
    }
  }, [timeLeft, onClose]);

  return (
    <Modal
      isOpen={showModal}
      toggle={onClose}
      centered
      fullscreen={false}
      size={size} // Accepts 'sm', 'md', 'lg', 'xl'
    >
      <ModalBody>
        <div className='d-flex flex-column align-items-center pl-1 pr-1'>
          <AiSearch className='fill-blue-light h-px-27 w-px-27 mt-3 mb-2' />
          <span className='title'>Jury Profile Generation</span>
          <span className='description'>AI Generation Process will be finished in {timeLeft} sec ...</span>
          {/* Progress bar */}
          <Progress value={(30 - timeLeft) * (100 / 30)} className='progress-bar-container' />
          <ActionButton
            className='bg-red'
            title={BUTTON_TITLE.STOP_NOW}
            onClick={() => {
              if (onClose) onClose();
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
