import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";

import { ReactComponent as ChevronDownIcon } from "../../assets/images/icons/chevron-down.svg";

const LanguageDropdown = (): JSX.Element => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [lang, setLang] = useState("en");

  function changeLanguageAction(lng: string) {
    //set language as i18n
    setLang(lng);
    console.log(lang);
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect d-flex align-items-center' tag='button'>
          <span className='align-middle text-uppercase mr-2 font-size-15 font-weight-bold'>{lang}</span>
          <ChevronDownIcon />
        </DropdownToggle>
        <DropdownMenu className='language-switch' end>
          <DropdownItem tag='a' href='#' onClick={() => changeLanguageAction("en")} className='notify-item'>
            <span className='align-middle'>English</span>
          </DropdownItem>
          <DropdownItem tag='a' href='#' onClick={() => changeLanguageAction("es")} className='notify-item'>
            <span className='align-middle'>Spanish</span>
          </DropdownItem>
          <DropdownItem tag='a' href='#' onClick={() => changeLanguageAction("de")} className='notify-item'>
            <span className='align-middle'>German</span>
          </DropdownItem>
          <DropdownItem tag='a' href='#' onClick={() => changeLanguageAction("it")} className='notify-item'>
            <span className='align-middle'>Italian</span>
          </DropdownItem>
          <DropdownItem tag='a' href='#' onClick={() => changeLanguageAction("ru")} className='notify-item'>
            <span className='align-middle'>Russian</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
