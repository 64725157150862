import React, { useState } from "react";
import { WrapperComponent } from "../../../WrapperComponent";
import {
  ACTION_COLUMN_STYLE,
  BUTTON_TITLE,
  CASE_TABS,
  baseCaseEvidenceColumns,
  caseEvidenceGridData
} from "../../../../utils/constants";
import AgGrid from "../../../AgGrid";
import { ActionButton } from "../../../ActionButton";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { CaseEvidence, CaseEvidenceListProps } from "../../../../utils/types";

const CaseEvidenceList: React.FC<CaseEvidenceListProps> = ({ handleTabChange }) => {
  const [evidenceData, setEvidenceData] = useState<CaseEvidence[]>(caseEvidenceGridData);

  const handleDelete = (evidence: CaseEvidence) => {
    setEvidenceData((prevData) => prevData.filter((item) => item.id !== evidence.id));
  };

  const handleDownload = (data: CaseEvidence) => {
    alert(`Downloading document with id: ${data?.id}`);
  };

  const EvidenceColumns: ColDef[] = [
    ...baseCaseEvidenceColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellStyle: { borderColor: "transparent" },
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onEdit={handleDownload}
          onDelete={() => handleDelete(params.data as CaseEvidence)}
          download
        />
      )
    }
  ];

  return (
    <div className='d-flex h-100 flex-column'>
      <WrapperComponent title='Evidence Documents' customClass={"mb-2"}>
        <div className='d-flex' style={{ height: "60vh" }}>
          <AgGrid columnDefs={EvidenceColumns} rowData={evidenceData} />
        </div>
      </WrapperComponent>

      <ActionButton
        className='save-button continue-button mt-2 mb-2'
        onClick={() => {
          handleTabChange?.(CASE_TABS.DOCUMENTS);
        }}
        title={BUTTON_TITLE.CONTINUE}
      />
    </div>
  );
};

export default CaseEvidenceList;
