import { Form, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { ActionButton, TextInput } from "../../components";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, FORM_PLACEHOLDERS } from "../../utils/constants";
import { CaseEvidence, EvidenceFormValues } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatFileSize } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { addEvidenceDocument } from "../../redux/slices/documentsSlice";
import { toast } from "react-toastify";

const CaseEvidenceForm: React.FC = () => {
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormikContext<EvidenceFormValues>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddNewDocument = useCallback(
    (values: EvidenceFormValues) => {
      if (!values.files || values.files.length === 0) {
        toast.error("No file selected");
        return;
      }

      const file = values.files[0];
      const fileExtension = file.name.split(".").pop();
      const fileSize = formatFileSize(file.size);

      const newDocument: CaseEvidence = {
        id: Math.random(),
        description: file.name || values.documentName || "",
        docType: fileExtension ? `.${fileExtension}` : "unknown",
        size: fileSize,
        dateAdded: moment().format("DD/MM/YYYY"),
        addedBy: values.addedBy || "Anerva"
      };

      dispatch(addEvidenceDocument(newDocument));
      resetForm();
      toast.success("Evidence document added successfully");
    },
    [dispatch, resetForm]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <TextInput
            label={FORM_LABELS.EVIDENCE_DESCRIPTION}
            name={FORM_NAMES.EVIDENCE_DESCRIPTION}
            errorMessage={errors?.evidenceDescription}
            value={values?.evidenceDescription}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
          />
          <TextInput
            label={FORM_LABELS.DOC_TYPE}
            name={FORM_NAMES.DOC_TYPE}
            errorMessage={errors?.docType}
            value={values?.docType}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
          />
          <TextInput
            label={FORM_LABELS.CASE_NUMBER}
            name={FORM_NAMES.CASE_NUMBER}
            errorMessage={errors?.caseNumber}
            value={values?.caseNumber}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
            icon={<i className='fa-regular fa-plus color-primary-light cursor-pointer'></i>}
          />
          <TextInput
            label={FORM_LABELS.CLIENT_NAME_S}
            name={FORM_NAMES.CLIENT_NAME_S}
            errorMessage={errors?.clientName}
            value={values?.clientName}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
            icon={<i className='fa-regular fa-plus color-primary-light cursor-pointer'></i>}
          />
          <div className='w-50'>
            <TextInput
              label={FORM_LABELS.DATE_ADDED}
              name={FORM_NAMES.DATE_ADDED}
              errorMessage={errors?.dateAdded}
              type='date'
              value={values?.dateAdded}
              placeholder={FORM_PLACEHOLDERS.DEFAULT}
              onChange={handleChange}
            />
          </div>
          <TextInput
            label={FORM_LABELS.ADDED_BY}
            name={FORM_NAMES.ADDED_BY}
            errorMessage={errors?.addedBy}
            value={values?.addedBy}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
          />
          <TextInput
            label={FORM_LABELS.ROLE}
            name={FORM_NAMES.ROLE}
            errorMessage={errors?.role}
            value={values?.role}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
          />
        </div>
        <div className='w-100'>
          <TextInput
            label={FORM_LABELS.EVIDENCE_VIEWER}
            name={FORM_NAMES.CASE_NAME}
            textAreaClassName='h-100'
            rows={25}
            type='textArea'
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
          />
          <TextInput label={""} placeholder={FORM_PLACEHOLDERS.COMMENT} onChange={handleChange} />
        </div>
      </div>
      <div className='d-flex flex-row gap-3 sm-flex-wrap'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleAddNewDocument(values);
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};

export default CaseEvidenceForm;
