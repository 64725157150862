import React from "react";
import { titleMap, TITLES } from "../../utils/constants";
import "./styles.scss";
import NavigationWrapper from "../../components/NavigationWrapper";
import { BreadCrumbs } from "../../components";
import routes from "../../utils/constants/routes";
import { addCandidate, addWitness, addExpert, addParticipant } from "../../redux/slices/participantsSlice";
import { useDispatch } from "react-redux";
import AddNewPerson from "../../components/Pages/AddNewPerson";
import { getFirstWord } from "../../utils/helpers";
import { AddNewFormValues, Participant } from "../../utils/types";
import { toast } from "react-toastify";

const AddNewClient: React.FC = () => {
  const dispatch = useDispatch();
  const pathSegment = location.pathname.split("/").pop();
  const title = titleMap[pathSegment as keyof typeof titleMap] || TITLES.ADD_NEW_CANDIDATE;
  const role = getFirstWord(title);

  const handleSubmit = (values: AddNewFormValues) => {
    const participant: Participant = {
      ...values,
      phone: values.phoneNumber || "N/A"
    };

    switch (role) {
      case "Candidate":
        dispatch(addCandidate(participant));
        toast.success("Candidate added successfully");
        break;
      case "Witness":
        dispatch(addWitness(participant));
        toast.success("Witness added successfully");
        break;
      case "Expert":
        dispatch(addExpert(participant));
        toast.success("Expert added successfully");
        break;
      case "Participant":
        dispatch(addParticipant(participant));
        toast.success("Participant added successfully");

        break;
      default:
        break;
    }
  };

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Participants", url: location.pathname }
        ]}
      />
      <NavigationWrapper title={title}>
        <AddNewPerson onSubmit={handleSubmit} />
      </NavigationWrapper>
    </div>
  );
};

export default AddNewClient;
