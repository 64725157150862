import React, { useState } from "react";
import "./styles.juryBox.scss";
import StatisticsCardContainer from "../../TrailTeamStatistics/StatisticsCardContainer";
import {
  barChartData,
  donutChartData,
  CaseNames,
  jurorData,
  STRINGS,
  jurorGridData,
  ACTION_COLUMN_STYLE,
  baseJurorColumns
} from "../../../../utils/constants";
import { BarChart, DonughtChart } from "../../..";
import AgGrid from "../../../AgGrid";
import SelectComponent from "../../../SelectComponent";
import JurorCard from "./JurorCard";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import RangeSlider from "../../../AgGrid/GridRangeSlider";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import DismissedJurors from "./DismissedJurors";
import AddJurorCard from "./AddJurorCard";
import { useNavigate } from "react-router-dom";
import routes from "../../../../utils/constants/routes";
import PersonInfoModal from "../../../PersonInfoModal";
import { Juror, PersonInfo } from "../../../../utils/types";
import StrikeJurorModal from "../../../StrikeJurorModal";

type JuryBoxProps = unknown;

const JuryBox: React.FC<JuryBoxProps> = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const [openStrikeModal, setOpenStrikeModal] = useState<boolean>(false);
  const [jurorsData, setJurorsData] = useState<Juror[]>(jurorGridData);

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDelete = (juror: Juror) => {
    setJurorsData((prevJurors) => prevJurors.filter((item) => item.email !== juror.email));
  };

  const JurorColumns: ColDef[] = [
    ...baseJurorColumns,
    {
      field: "jurorRate",
      headerName: "Juror Rate",
      sortable: true,
      unSortIcon: true,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider value={params.value as number} min={0} max={100} onClick={() => setOpenStrikeModal(true)} />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,

      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onEdit={() => navigate(routes.JUROR_DETAILS)}
          onDelete={() => handleDelete(params.data as Juror)}
        />
      )
    }
  ];

  return (
    <>
      <div className='jury-box-tab'>
        <div className='d-flex flex-row justify-content-between'>
          <div>
            <h5>{STRINGS.JURY_BOX}</h5>
            <p>{STRINGS.DRAG_AND_DROP_PARA}</p>
          </div>

          <div className=' d-flex flex-row gap-2 negative-top-margin'>
            <SelectComponent
              label={" "}
              name={"caseName"}
              errorMessage={"Case Name Error"}
              items={CaseNames}
              value={"Case Status"}
              onChange={(e) => console.log(e)}
            />
            <SelectComponent
              label={""}
              name={"caseName"}
              errorMessage={"Case Name Error"}
              items={CaseNames}
              value={"Case Status"}
              onChange={(e) => console.log(e)}
            />
          </div>
        </div>

        <div className='main-content-con'>
          <div className='left-section'>
            <div className='graphs-con'>
              <StatisticsCardContainer headerText='Total Satisfaction' numberWithPercentage='50% 30% 10%'>
                <DonughtChart data={donutChartData} />
              </StatisticsCardContainer>
              <StatisticsCardContainer headerText='Trial Team Overall Rating'>
                <BarChart data={barChartData} horizontal={false} />
              </StatisticsCardContainer>
            </div>

            <div className='juror-cards'>
              {jurorData?.map((juror, index) => <JurorCard key={index} juror={juror} />)}
              <AddJurorCard onClick={() => navigate(routes.ADD_NEW_JUROR)} />
            </div>
          </div>

          <div className='table-con'>
            <h5>{STRINGS.JURY_SELECTION}</h5>
            <p>{STRINGS.DRAG_AND_DROP_PARA}</p>

            <div className='ag-grid'>
              <AgGrid columnDefs={JurorColumns} rowData={jurorsData} />
            </div>
          </div>
        </div>
      </div>

      <DismissedJurors />

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
      <StrikeJurorModal
        isOpen={openStrikeModal}
        onClose={() => setOpenStrikeModal(false)}
        onSubmit={() => setOpenStrikeModal(false)}
      />
    </>
  );
};

export default JuryBox;
