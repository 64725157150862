import React from "react";
import "./styles.jurorCard.scss";
import { JurorCardProps } from "../../../../../utils/types";
import { STRINGS } from "../../../../../utils/constants";

const JurorCard = ({ juror, dismissed }: JurorCardProps) => {
  return (
    <div className={`juror-card ${dismissed ? "dismissed" : ""}`}>
      {/* <div className='juror-card__index'>{juror.index}</div> */}
      {/* if index is in two digit then it mut goes downward like one letter above second one below */}
      <div className='juror-card__index'>
        {String(juror.index)
          .split("")
          .map((digit, idx) => (
            <span key={idx} className='juror-card__index-digit'>
              {digit}
            </span>
          ))}
      </div>

      <div className='juror-card__header'>
        <div className='juror-card__options'>
          <i className='fa-solid fa-ellipsis-vertical'></i>
        </div>
        <div className='juror-card__image'>
          <img src={juror.image} alt={juror.name} />
        </div>
        <div className='juror-card__emoji'>
          {juror.icon?.map((iconClass, idx) => (
            <span key={idx} role='img' aria-label={`icon-${idx}`}>
              <i className={iconClass}></i>
            </span>
          ))}
        </div>
      </div>

      <div className='juror-card__info'>
        <div className='juror-card__name'>{juror.name}</div>
        <div className='d-flex flex-row justify-content-between'>
          <p className='juror-card__id'>Juror ID | {juror.id}</p>
          <p className='juror-card__notes'>{juror.notes} notes</p>
        </div>
      </div>

      {dismissed ? (
        <div className='juror-card__message'>
          <p>{STRINGS.JUROR_HAS_BEEN_DISMISSED}</p>
        </div>
      ) : (
        <div className='juror-card__actions'>
          <span className='icon-button eye-icon-row' onClick={() => {}}>
            <i className='fa-regular fa-eye'></i>
          </span>
          <span className='icon-button edit-icon-row' onClick={() => {}}>
            <i className='fa-regular fa-pen-to-square'></i>
          </span>
          <span className='icon-button delete-icon-row' onClick={() => {}}>
            <i className='fa-regular fa-trash'></i>
          </span>
        </div>
      )}
    </div>
  );
};

export default JurorCard;
