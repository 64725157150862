import { Form, useFormikContext } from "formik";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  personalDataPageTabs
} from "../../../../../utils/constants";
import { PersonalDataProps, PoliticalAndSocialFormValueProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import TextInput from "../../../../TextInput";

export const PoliticalAndSocialDetailsForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<PoliticalAndSocialFormValueProps>();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.PARTY_AFFILIATION}
          name={FORM_NAMES.PARTY_AFFILIATION}
          errorMessage={errors?.partyAffiliation}
          value={values?.partyAffiliation}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.VOTING_RECORDS}
          name={FORM_NAMES.VOTING_RECORDS}
          errorMessage={errors?.votingRecords}
          value={values?.votingRecords}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.DONOR_INFORMATION}
          name={FORM_NAMES.DONOR_INFORMATION}
          errorMessage={errors?.donorInformation}
          value={values?.donorInformation}
          checked={values.isDonorInformationAvailable}
          switchLabel={{ on: "Yes", off: "No" }}
          isSwitch
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.IS_DONOR_INFORMATION_AVAILABLE, e.target.checked)}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <div className='w-100'></div>
      </div>
      <div className='d-flex gap-2'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[6]);
            resetForm();
          }}
          className='bg-primary-light'
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[4]);
            resetForm();
          }}
          className='bg-red'
        />
      </div>
    </Form>
  );
};
