import React from "react";
import { JSXIconProps } from "../../utils/types";

export const PhoneSVG = (props: JSXIconProps) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width={28}
      height={28}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={28} height={28} rx={14} fill='#137C4E' />
      <path d='M17.75 14.5938L21.25 16.0938C21.5208 16.2188 21.7292 16.4167 21.875 16.6875C22 16.9375 22.0312 17.2188 21.9688 17.5312L21.2188 21.0312C21.0521 21.6354 20.6458 21.9583 20 22C19.8125 22 19.625 22 19.4375 22C19.125 21.9792 18.8125 21.9583 18.5 21.9375C16.125 21.6458 14 20.8646 12.125 19.5938C10.25 18.3021 8.77083 16.6562 7.6875 14.6562C6.58333 12.6562 6.02083 10.4375 6 8C6.04167 7.35417 6.375 6.94792 7 6.78125L10.5 6.03125C10.7917 5.96875 11.0625 6.01042 11.3125 6.15625C11.5833 6.28125 11.7812 6.47917 11.9062 6.75L13.4062 10.25C13.6146 10.8125 13.4896 11.3021 13.0312 11.7188L11.7812 12.75C12.6354 14.2083 13.7917 15.3646 15.25 16.2188L16.2812 14.9688C16.6979 14.5104 17.1875 14.3854 17.75 14.5938ZM19.8125 20.5L20.4688 17.4062L17.3438 16.0625L16.4062 17.1875C16.1562 17.4583 15.8646 17.625 15.5312 17.6875C15.1771 17.7708 14.8333 17.7188 14.5 17.5312C12.8125 16.5312 11.4688 15.1875 10.4688 13.5C10.3021 13.1667 10.25 12.8333 10.3125 12.5C10.375 12.1458 10.5417 11.8438 10.8125 11.5938L11.9375 10.6875L10.5938 7.53125L7.5 8.21875C7.5625 10.4896 8.14583 12.5417 9.25 14.375C10.3333 16.2083 11.7917 17.6667 13.625 18.75C15.4583 19.8542 17.5208 20.4375 19.8125 20.5Z' />
    </svg>
  );
};
