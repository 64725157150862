import React, { useState, useCallback } from "react";
import TextInput from "../../TextInput";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_PLACEHOLDERS,
  FORM_NAMES,
  NEW_CLIENT_TABS,
  personalDataPageTabs
} from "../../../utils/constants";
import { ActionButton } from "../../ActionButton";
import { SocialMediaField, SocialMediaLinksRecordsProps } from "../../../utils/types";
import ImageAttachment from "../../ImageAttachment";
import AddMore from "../../AddMoreButton";
import AddSocialMediaModal from "./AddSocialMediaModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SocialMediaAndOnlinePresence: React.FC<SocialMediaLinksRecordsProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, handleTabChange, resetForm } = props;
  const [socialMediaFields, setSocialMediaFields] = useState<SocialMediaField[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

  const handleAddSocialMedia = useCallback(
    (label: string) => {
      const newField: SocialMediaField = {
        name: `social_field_${socialMediaFields.length}`,
        label
      };
      setSocialMediaFields((prev) => [...prev, newField]);
      toggleModal();
    },
    [socialMediaFields.length, toggleModal]
  );

  const handleRemoveField = useCallback(
    (index: number) => {
      setSocialMediaFields((prev) => prev.filter((_, i) => i !== index));

      const fieldName = `social_field_${index}`;
      setFieldValue(fieldName, "");
    },
    [setFieldValue]
  );

  const handleCopy = useCallback((value: string) => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          toast.success(`${value} copied to clipboard!`);
        })
        .catch((err) => {
          toast.error("Failed to copy text.");
          console.error("Failed to copy text: ", err);
        });
    }
  }, []);

  return (
    <>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.TWITTER}
          name={FORM_NAMES.TWITTER}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          value={values.twitter}
          onChange={handleChange}
          errorMessage={errors.twitter}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.twitter ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.twitter)}
        />
        <TextInput
          label={FORM_LABELS.LINKEDIN}
          name={FORM_NAMES.LINKEDIN}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          value={values.linkedin}
          onChange={handleChange}
          errorMessage={errors.linkedin}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.linkedin ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.linkedin)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.FACEBOOK}
          name={FORM_NAMES.FACEBOOK}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          value={values.facebook}
          onChange={handleChange}
          errorMessage={errors.facebook}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.facebook ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.facebook)}
        />
        <TextInput
          label={FORM_LABELS.INSTAGRAM}
          name={FORM_NAMES.INSTAGRAM}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          value={values.instagram}
          onChange={handleChange}
          errorMessage={errors.instagram}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.instagram ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.instagram)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.REDDIT}
          name={FORM_NAMES.REDDIT}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          value={values.reddit}
          onChange={handleChange}
          errorMessage={errors.reddit}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.reddit ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.reddit)}
        />

        <TextInput
          label={FORM_LABELS.YOUTUBE}
          name={FORM_NAMES.YOUTUBE}
          value={values.youtube}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          errorMessage={errors.youtube}
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.youtube ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.youtube)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <TextInput
          label={FORM_LABELS.TIKTOK}
          name={FORM_NAMES.TIKTOK}
          value={values.tiktok}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          errorMessage={errors.tiktok}
          parentClassName='w-100'
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.tiktok ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.tiktok)}
        />
        <div
          className={`d-flex flex-row w-100 align-items-center sm-flex-wrap ${socialMediaFields.length > 0 ? "justify-content-between" : ""}`}
        >
          <div className='d-flex flex-column '>
            {socialMediaFields.map(({ name, label }, index) => (
              <div key={index} className='d-flex flex-row  sm-flex-wrap align-items-center'>
                <TextInput
                  label={label}
                  name={name}
                  placeholder={FORM_PLACEHOLDERS.DASHED}
                  value={(values[name as keyof typeof values] as string) || ""}
                  onChange={handleChange}
                  errorMessage={(errors[name as keyof typeof errors] as string) || undefined}
                  icon={<i className='fa-light fa-copy'></i>}
                  iconClassName={
                    (values[name as keyof typeof values] as string)
                      ? "color-primary-light"
                      : "color-gray-light cursor-not-allowed"
                  }
                  onClickIcon={() => handleCopy(values[name as keyof typeof values] as string)}
                />
                <div className='trash-container' onClick={() => handleRemoveField(index)}>
                  <i className='fa-regular fa-trash'></i>
                </div>
              </div>
            ))}
          </div>
          <div className='d-flex mt-2 align-items-center'>
            <AddMore title={BUTTON_TITLE.ADD_SOCIAL_MEDIA} onClick={toggleModal} />
          </div>
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <TextInput
          label={FORM_LABELS.WEBSITE_BLOG_URLS}
          name={FORM_NAMES.WEBSITE_BLOG_URLS}
          value={values.website_blog_urls}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          errorMessage={errors.website_blog_urls}
          parentClassName='w-100'
          icon={<i className='fa-light fa-copy'></i>}
          iconClassName={values.website_blog_urls ? "color-primary-light" : "color-gray-light cursor-not-allowed"}
          onClickIcon={() => handleCopy(values.website_blog_urls)}
        />
        <div className='w-100'></div>
      </div>

      <div className='d-flex flex-column sm-flex-wrap w-100'>
        <p className='my-2 color-primary-light font-size-15'>Additional Photos and Videos from Social Media Profiles</p>
        <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
          <ImageAttachment
            onFileChange={(file) => setFieldValue("clientPhoto", file)}
            placeholder={FORM_PLACEHOLDERS.ADD_NEW_PHOTO_VIDEO}
            multiple
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            if (props?.juror) {
              handleTabChange?.(personalDataPageTabs[4]);
            } else {
              navigate(-1);
            }
            resetForm?.();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            if (props?.juror) {
              handleTabChange?.(personalDataPageTabs[2]);
            } else {
              handleTabChange?.(NEW_CLIENT_TABS[2]);
            }
            resetForm?.();
          }}
          className='cancel-button'
        />
      </div>

      <AddSocialMediaModal isOpen={isModalOpen} toggle={toggleModal} onSubmit={handleAddSocialMedia} />
    </>
  );
};

export default SocialMediaAndOnlinePresence;
