import routes from "../constants/routes";
import { MenuItem, ProfileMenuItem, SelectedReasons } from "../types";

export const getEnv = (): "staging" | "prod" | "dev" | "local" => {
  const _env = (process.env.REACT_APP_ENV || "").toLowerCase().trim();
  console.log("getEnv = ", _env, " and ", process.env.REACT_APP_ENV);
  if (_env === "staging") return "staging";
  if (_env === "prod") return "prod";
  if (_env === "production") return "prod";
  if (_env === "") return "local";
  if (_env === "local") return "local";
  return "dev";
};

export const getSearchEnv = () => {
  if (getEnv() === "staging") return "staging";
  if (getEnv() === "prod") return "production";
  if (getEnv() === "local") return "local";
  return "dev";
};

export const menuItems: Array<MenuItem> = [
  {
    item_type: "Label",
    title_en: "MY WORKFLOW",
    view_groups: null
  },
  {
    item_type: "Custom",
    title_en: "Dashboard",
    icon: "fa-light fa-table-columns",
    view_groups: null,
    route: routes.DASHBOARD,
    children: []
  },
  {
    item_type: "Custom",
    title_en: "Interactive Calendar",
    icon: "fa-light fa-calendars",
    view_groups: null,
    route: routes.CALENDARS,
    children: []
  },
  {
    item_type: "Label",
    title_en: "Case MODULES",
    view_groups: null
  },
  {
    item_type: "Custom",
    title_en: "Create Case",
    icon: "fa-light fa-briefcase",
    view_groups: null,
    route: routes.CREATE_CASE,
    children: []
    //  [
    //   {
    //     item_type: "Custom",
    //     subtitle_en: "Trial Team",
    //     tooltip_en: "Trial Team",
    //     icon: "fas fa-clock",
    //     route: routes.TRIAL_TEAM,
    //     view_groups: null
    //   },
    //   {
    //     item_type: "Custom",
    //     subtitle_en: "Documents",
    //     tooltip_en: "Documents",
    //     icon: "fa-regular fa-paperclip",
    //     route: routes.DOCUMENTS,
    //     view_groups: null
    //   }
    // ]
  },
  {
    item_type: "Custom",
    title_en: "Manage Case",
    icon: "fa-light fa-table",
    view_groups: null,
    route: routes.CASES_LIST,
    children: []
  }
];

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: "Action",
    text_en: "Logout",
    icon: "fa fa-power-off",
    action: () => {}
  }
];

export const getUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomNum = Math.random().toString(36).substr(2, 5); // Adjust the length as needed

  return `${timestamp}-${randomNum}`;
};

export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
  return `${(bytes / 1048576).toFixed(2)} MB`;
};

export const calculateNewRate = (currentRate: string, selectedReasons: SelectedReasons) => {
  let rateDeduction = 0;
  if (selectedReasons.biasParty) rateDeduction += 20;
  if (selectedReasons.biasSubject) rateDeduction += 15;
  if (selectedReasons.cause) rateDeduction += 25;
  if (selectedReasons.excused) rateDeduction += 10;
  if (selectedReasons.peremptory) rateDeduction += 30;
  if (selectedReasons.other) rateDeduction += 5;
  const value = parseInt(currentRate, 10);
  const newRate = value * (1 - rateDeduction / 100);
  return Math.min(Math.max(0, newRate), 100);
};

export const getFirstWord = (str: string): string => {
  const firstWord = str.split(" ")[0];
  return firstWord.replace(":", "");
};
