import React from "react";
import "./styles.AddJurorCard.scss";
import { STRINGS } from "../../../../../utils/constants";
import { AddJurorCardProps } from "../../../../../utils/types";

const AddJurorCard = ({ onClick }: AddJurorCardProps) => {
  return (
    <div className={"juror-card-add"} onClick={onClick}>
      <div className='juror-card-add__index'>{"-"}</div>

      <div className='juror-card-add__header'>
        <div className='juror-card-add__options'>
          <i className='fa-solid fa-ellipsis-vertical gray-icon'></i>
        </div>
        <div className='juror-card-add__image'>
          <i className='fa-regular fa-plus add-icon'></i>
        </div>
        <div className='juror-card-add__emoji'>
          <i className='fa-regular fa-face-meh-blank gray-icon'></i>
        </div>
      </div>

      <div className='juror-card-add__info'>
        <div className='juror-card-add__name'>{STRINGS.ADD_ALTERNATE_JUROR}</div>
      </div>

      <div className='juror-card-add__actions'>
        <span className='icon-button'>
          <i className='fa-regular fa-eye'></i>
        </span>
        <span className='icon-button'>
          <i className='fa-regular fa-pen-to-square'></i>
        </span>
        <span className='icon-button'>
          <i className='fa-regular fa-trash'></i>
        </span>
      </div>
    </div>
  );
};

export default AddJurorCard;
