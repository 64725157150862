import React, { useCallback, useState } from "react";
import AgGrid from "../../../AgGrid";
import WrapperComponent from "../../../WrapperComponent";
import { ACTION_COLUMN_STYLE, baseJurorColumns } from "../../../../utils/constants";
import RangeSlider from "../../../AgGrid/GridRangeSlider";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import PersonInfoModal from "../../../PersonInfoModal";
import { PersonInfo } from "../../../../utils/types";
import StrikeJurorModal from "../../../StrikeJurorModal";
import { Juror } from "../../../../utils/types";
import { useNavigate } from "react-router-dom";
import routes from "../../../../utils/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { deleteJuror, updateJuror } from "../../../../redux/slices/jurorSlice";
import { RootState } from "../../../../redux/types";
import { calculateNewRate } from "../../../../utils/helpers";

const TrialTeamTab: React.FC = () => {
  const [openStrikeModal, setOpenStrikeModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const [selectedJurorForStrike, setSelectedJurorForStrike] = useState<Juror | null>(null);

  const jurorsData = useSelector((state: RootState) => state.juror.juror);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDelete = useCallback(
    (data: Juror) => {
      dispatch(deleteJuror(data?.email || ""));
    },
    [dispatch]
  );

  const handleStrikeSubmit = useCallback(
    (data) => {
      if (selectedJurorForStrike) {
        const currentRate = selectedJurorForStrike?.jurorRate || "0";
        const newRate = calculateNewRate(currentRate, data.selectedReasons);
        const updatedJurors = { ...selectedJurorForStrike, jurorRate: `${newRate}%` };
        dispatch(updateJuror(updatedJurors));
        setOpenStrikeModal(false);
        setSelectedJurorForStrike(null);
      }
    },
    [dispatch, selectedJurorForStrike]
  );

  const handleOpenStrikeModal = (juror: Juror) => {
    setSelectedJurorForStrike(juror);
    setOpenStrikeModal(true);
  };

  const JurorColumns: ColDef[] = [
    ...baseJurorColumns,
    {
      field: "jurorRate",
      headerName: "Juror Rate",
      sortable: true,
      unSortIcon: true,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={params.value as number}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onEdit={() => navigate(routes.JUROR_DETAILS, { state: params.data })}
          onDelete={() => handleDelete(params.data as Juror)}
        />
      )
    }
  ];

  return (
    <WrapperComponent title='Trial Team' customClass='mb-2'>
      <div style={{ height: "60vh" }}>
        <AgGrid
          columnDefs={JurorColumns}
          rowData={jurorsData}
          suppressContextMenu={true}
          getContextMenuItems={() => {
            return [];
          }}
          tooltipShowDelay={0}
          enableBrowserTooltips={true}
        />
      </div>

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
      <StrikeJurorModal
        isOpen={openStrikeModal}
        onClose={() => {
          setOpenStrikeModal(false);
          setSelectedJurorForStrike(null);
        }}
        onSubmit={handleStrikeSubmit}
      />
    </WrapperComponent>
  );
};

export default TrialTeamTab;
