/* eslint-disable react-hooks/exhaustive-deps */
import "./styles/index.scss";

import React, { useEffect, useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { menuItems, profileMenuItems, getEnv } from "./utils/helpers";
import packageInfo from "../package.json";
import {
  Dashboard,
  CreateCase,
  InteractiveCalendar,
  AddNewClient,
  TrialTeam,
  JurorDetails,
  CaseEvidence,
  Documents,
  CaseList,
  ManageCase,
  EditNewClient,
  AddNewJuror
} from "./pages";
import Layout from "./components/AdminPortalLayout";
import { AnervaLogo, AnervaSmLogo } from "./assets/images";
import { ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "./utils/constants/routes";
import { Tooltip } from "react-tooltip";
import { ANERVA, FORM_PLACEHOLDERS, POSITIONS, STRINGS, THEME } from "./utils/constants";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleTheme();
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      console.log("Window Event:", event);
    });
  }, []);

  const handleTheme = () => {
    document.body.classList.add("theme-" + "anerva");
  };

  const env = useMemo((): string => getEnv(), []);
  const handleSearch = (str: string) => {
    str = str.replace("_task", "");
    navigate(`/page/search/${str}`);
  };

  return (
    <>
      <Routes>
        <Route
          path={routes.HOME}
          element={React.createElement(Layout, {
            menuItems,
            profileMenuItems,
            logoImage: AnervaLogo,
            logoSmImage: AnervaSmLogo,
            username: ANERVA,
            hideTranslate: true,
            showWorldClock: false,
            versionNumber: packageInfo.version,
            env: env,
            showGearIcon: false,
            showNotification: true,
            notifCount: 20,
            notificationIconType: STRINGS.REGULAR,
            searchPlaceholder: FORM_PLACEHOLDERS.SMART_SEARCH,
            handleSetting: () => {},
            handleSearch: handleSearch
          })}
        >
          <Route path={routes.HOME} Component={Dashboard}></Route>
          <Route path={routes.DASHBOARD} Component={Dashboard}></Route>
          <Route path={routes.CALENDARS} Component={InteractiveCalendar}></Route>
          <Route path={routes.CREATE_CASE} Component={CreateCase}></Route>
          <Route path={routes.TRIAL_TEAM} element={<TrialTeam />} />
          <Route path={routes.CASE_EVIDENCE} Component={CaseEvidence}></Route>
          <Route path={routes.ADD_NEW_CANDIDATE} Component={AddNewClient}></Route>
          <Route path={routes.ADD_NEW_WITNESS} Component={AddNewClient} />
          <Route path={routes.ADD_NEW_EXPERTS} Component={AddNewClient} />
          <Route path={routes.ADD_NEW_PARTICIPANTS} Component={AddNewClient} />
          <Route path={routes.JUROR_DETAILS} Component={JurorDetails}></Route>
          <Route path={routes.DOCUMENTS} Component={Documents}></Route>
          <Route path={routes.CASES_LIST} Component={CaseList}></Route>
          <Route path={routes.MANAGE_CASE} Component={ManageCase}></Route>
          <Route path={routes.EDIT_NEW_CANDIDATE} Component={EditNewClient}></Route>
          <Route path={routes.EDIT_NEW_EXPERTS} Component={EditNewClient} />
          <Route path={routes.EDIT_NEW_PARTICIPANTS} Component={EditNewClient} />
          <Route path={routes.EDIT_NEW_WITNESS} Component={EditNewClient} />
          <Route path={routes.ADD_NEW_JUROR} Component={AddNewJuror} />
        </Route>
      </Routes>
      <Tooltip id='tooltip' />

      <ToastContainer
        position={POSITIONS.BOTTOM_RIGHT as ToastPosition}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={THEME.LIGHT}
      />
    </>
  );
};

export default App;
