import React from "react";
import "./styles.dismissedJurors.scss";
import { dismissedJurorData } from "../../../../../utils/constants";
import JurorCard from "../JurorCard";

type DismissedJurors = unknown;

const DismissedJurors: React.FC<DismissedJurors> = () => {
  return (
    <div className='dismissed-jurors-section'>
      <h4>Dismissed Jurors</h4>
      <div className='juror-cards'>
        {dismissedJurorData?.map((juror, index) => <JurorCard key={index} juror={juror} dismissed={true} />)}
      </div>
    </div>
  );
};

export default DismissedJurors;
