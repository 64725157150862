import React, { useCallback } from "react";
import TextInput from "../../../TextInput";
import { BUTTON_TITLE } from "../../../../utils/constants";
import { ActionButton } from "../../../ActionButton";
import { Form, useFormikContext } from "formik";
import { AddNewCaseFormValues } from "../../../../utils/types";
import { FORM_LABELS, FORM_PLACEHOLDERS, FORM_NAMES } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCase } from "../../../../redux/slices/casesSlices";
import { CaseListData } from "../../../../utils/types";
import { toast } from "react-toastify";

const AddCase: React.FC = () => {
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormikContext<AddNewCaseFormValues>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    const newCase: CaseListData = {
      caseName: values.caseName,
      caseNumber: values.courtCaseNumber,
      action: "action"
    };

    dispatch(addCase(newCase));
    toast.success("Case Added successfully");
    resetForm();
    // navigate(-1);
  }, [dispatch, resetForm, values]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CASE_NAME}
          name={FORM_NAMES.CASE_NAME}
          errorMessage={errors?.caseName}
          value={values?.caseName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.COURT_CASE_NUMBER}
          name={FORM_NAMES.COURT_CASE_NUMBER}
          type='number'
          errorMessage={errors?.courtCaseNumber}
          value={values?.courtCaseNumber}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <ActionButton title={BUTTON_TITLE.SAVE} className='save-button' onClick={handleSave} />
        {/* <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />  */}
      </div>
    </Form>
  );
};

export default AddCase;
