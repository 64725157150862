/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React from "react";
import SidebarContent from "./SidebarContent";

import { Sidebar_Props } from "./layout_types";

const Sidebar = ({
  menuItems,
  logoImage,
  logoSmImage,
  leftSideBarType,
  setLeftSideBarType,
  hasDropdownMenu,
  dropdownChangeHandler,
  dropdownPlaceholder,
  dropdownValue,
  dropdownOptions,
  showWorldClock = true,
  versionNumber,
  env
}: Sidebar_Props) => {
  const handleMouseLeave = () => {
    if (leftSideBarType === "menu-opened") {
      setLeftSideBarType("menu-opened");
    }
  };

  return (
    <React.Fragment>
      <div className='vertical-menu' onMouseLeave={handleMouseLeave}>
        <div data-simplebar style={{ height: showWorldClock ? "calc(100vh - 30px)" : "100%" }}>
          <SidebarContent
            menuItems={menuItems}
            logoImage={logoImage}
            logoSmImage={logoSmImage}
            hasDropdownMenu={hasDropdownMenu}
            dropdownValue={dropdownValue}
            dropdownChangeHandler={dropdownChangeHandler}
            dropdownPlaceholder={dropdownPlaceholder}
            dropdownOptions={dropdownOptions}
            showWorldClock={showWorldClock}
            versionNumber={versionNumber}
            env={env}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
