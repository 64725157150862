import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BreadCrumbItemsProps } from "../../utils/types";
import "./breadcrumbs.scss";

/**
 * BreadCrumbs component
 *
 * @component BreadCrumbs
 * @category PageElements
 */
const BreadCrumbs = ({ breadcrumbItems }: BreadCrumbItemsProps) => {
  const locationPath = window.location.pathname;
  return (
    <Row>
      <Col xs='12'>
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
          <h4 className='mb-0 font-size-18'>
            {breadcrumbItems.map((breadcrumbItem, i) => {
              return (
                <React.Fragment key={i}>
                  <Link
                    to={breadcrumbItem.url}
                    className={`breadcrumblink ${locationPath === breadcrumbItem.url ? "" : "active"}`}
                  >
                    {breadcrumbItem.text}
                  </Link>
                  {i < breadcrumbItems.length - 1 && (
                    <span className='chevron-right-icon mx-2'>
                      <i className='fa fa-chevron-right' aria-hidden='true'></i>
                    </span>
                  )}
                </React.Fragment>
              );
            })}
          </h4>
        </div>
      </Col>
    </Row>
  );
};

export default BreadCrumbs;
