import React, { useCallback, useMemo, useState } from "react";
import NavigationTab from "../../components/NavigationTabs";
import { BUTTON_TITLE, CASE_TABS, NavigationTabs, TITLES } from "../../utils/constants";
import { ActionButton, BreadCrumbs } from "../../components";
import {
  CaseEvidenceList,
  CaseTrialTeam,
  CreateCaseDocument,
  CreateNewCase,
  Documents,
  Participants
} from "../../components/Pages/CreateCase";
import NavigationWrapper from "../../components/NavigationWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../utils/constants/routes";
import { CaseEvidence, CaseListData } from "../../utils/types";
import { AppDispatch, RootState } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../redux/slices/manageCaseSlice";
import { addDocument } from "../../redux/slices/documentsSlice";
import { formatFileSize } from "../../utils/helpers";
import moment from "moment";
import { toast } from "react-toastify";

const ManageCase: React.FC = () => {
  const [documentModalShow, setDocumentModalShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const caseData = location.state as CaseListData | undefined;
  const dispatch = useDispatch<AppDispatch>();

  const tabs = useSelector((state: RootState) => state.manageCase.tabs);

  const handleTabChange = useCallback(
    (newActiveTab: string) => {
      dispatch(setActiveTab(newActiveTab));
    },
    [dispatch]
  );

  const renderActiveTabContent = () => {
    const activeTab = tabs.find((tab) => tab.status === "current")?.id;

    switch (activeTab) {
      case CASE_TABS.CASE_DETAILS:
        return <CreateNewCase handleTabChange={handleTabChange} caseData={caseData} />;
      case CASE_TABS.PARTICIPANTS:
        return <Participants handleTabChange={handleTabChange} />;
      case CASE_TABS.TRIAL_TEAM:
        return <CaseTrialTeam handleTabChange={handleTabChange} />;
      case CASE_TABS.CASE_EVIDENCE:
        return <CaseEvidenceList handleTabChange={handleTabChange} />;
      case CASE_TABS.DOCUMENTS:
        return <Documents handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  const renderActiveTabHeader = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.status === "current")?.id;

    switch (activeTab) {
      case CASE_TABS.TRIAL_TEAM:
        return (
          <div className='d-flex flex-row justify-content-between align-items-center gap-1'>
            <ActionButton
              className='bg-primary-light font-size-16 px-2'
              title={BUTTON_TITLE.UPLOAD_JURY_LIST}
              onClick={() => {
                alert(BUTTON_TITLE.UPLOAD_JURY_LIST);
              }}
              icon={<i className='fa-regular fa-users'></i>}
            />
            <ActionButton
              className='bg-blue font-size-16 px-3'
              title={BUTTON_TITLE.ADD_JUROR}
              onClick={() => navigate(routes.ADD_NEW_JUROR)}
              icon={<i className='fa-solid fa-plus'></i>}
            />
          </div>
        );
      case CASE_TABS.CASE_EVIDENCE:
        return (
          <div className='d-flex flex-row justify-content-between align-items-center gap-1'>
            <ActionButton
              className='bg-blue font-size-16 px-3'
              title={BUTTON_TITLE.ADD_NEW_EVIDENCE}
              onClick={() => {
                navigate(routes.CASE_EVIDENCE);
              }}
              icon={<i className='fa-solid fa-plus'></i>}
            />
            <ActionButton
              className='bg-primary-light font-size-16 px-2'
              title={BUTTON_TITLE.DOWNLOAD_FILES}
              onClick={() => {
                alert(BUTTON_TITLE.DOWNLOAD_FILES);
              }}
              icon={<i className='fa-regular fa-layer-group'></i>}
            />
          </div>
        );
      case CASE_TABS.DOCUMENTS:
        return (
          <div className='d-flex gap-1'>
            <ActionButton
              className='font-size-16 px-2'
              title={BUTTON_TITLE.ADD_DOCUMENT}
              icon={<i className='fa-regular fa-plus'></i>}
              onClick={() => {
                setDocumentModalShow(true);
              }}
            />
            <ActionButton
              className='bg-primary-light font-size-16 px-2'
              title={BUTTON_TITLE.DOWNLOAD_DOCUMENTS}
              icon={<i className='fa-regular fa-layer-group'></i>}
              onClick={() => {
                alert(BUTTON_TITLE.DOWNLOAD_DOCUMENTS);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  }, [navigate, tabs]);

  const handleAddNewDocument = useCallback(
    (values) => {
      const file = values.files[0];
      const fileExtension = file.name.split(".").pop();
      const fileSize = formatFileSize(file.size);
      const newDocument: CaseEvidence = {
        id: Math.random(),
        description: file.name || values.documentName || "",
        docType: fileExtension ? `.${fileExtension}` : "unknown",
        size: fileSize,
        dateAdded: moment().format("DD/MM/YYYY"),
        addedBy: "Anerva"
      };

      dispatch(addDocument(newDocument));
      toast.success("Document added successfully");
    },
    [dispatch]
  );

  return (
    <div className='scrollable-container'>
      <BreadCrumbs
        breadcrumbItems={[
          { text: "Case List", url: routes.CASES_LIST },
          { text: "Manage Case", url: routes.MANAGE_CASE }
        ]}
      />

      <NavigationWrapper
        title={TITLES.MANAGE_CASE}
        headerChild={renderActiveTabHeader}
        titleClass='color-primary-dark font-size-18 fw-bold'
      >
        <NavigationTab
          tabs={tabs}
          activeTab={tabs.find((tab) => tab.status === "current")?.id || NavigationTabs[0].id}
          setActiveTab={handleTabChange}
        />
        {renderActiveTabContent()}
      </NavigationWrapper>

      {documentModalShow && (
        <CreateCaseDocument
          showModal={documentModalShow}
          setShowModal={(value: boolean) => setDocumentModalShow(value)}
          onAddDocument={handleAddNewDocument}
        />
      )}
    </div>
  );
};

export default ManageCase;
