import React from "react";
import "./styles.EmojiValue.scss";
import { EmojiValueProps } from "../../../utils/types";

const EmojiValue: React.FC<EmojiValueProps> = ({ iconName, value }) => {
  return (
    <div className='emoji-value-con'>
      <span className='icon-14'>
        <i className={`fa-solid ${iconName}`}></i>
      </span>
      <div className='vertical-divider' />
      <p>{value}</p>
    </div>
  );
};

export default EmojiValue;
