import React from "react";
import { Form, useFormikContext } from "formik";
import { PersonalDataProps, SocialMediaLinks } from "../../../../../utils/types";
import SocialMediaAndOnlinePresence from "../../../SocialMediaAndOnlinePresence";

export const SmAndOnlinePresenceForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm } = useFormikContext<SocialMediaLinks>();

  return (
    <Form onSubmit={handleSubmit}>
      <SocialMediaAndOnlinePresence
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleTabChange={handleTabChange}
        resetForm={resetForm}
        juror={true}
      />
    </Form>
  );
};
