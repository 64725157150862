import React from "react";
import { JSXIconProps } from "../../utils/types";

export const FaceBookSVG = (props: JSXIconProps) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width={28}
      height={28}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={28} height={28} rx={14} fill='#2B7DFA' />
      <path d='M17.7188 15H15.375V22H12.25V15H9.6875V12.125H12.25V9.90625C12.25 7.40625 13.75 6 16.0312 6C17.125 6 18.2812 6.21875 18.2812 6.21875V8.6875H17C15.75 8.6875 15.375 9.4375 15.375 10.25V12.125H18.1562L17.7188 15Z' />
    </svg>
  );
};
