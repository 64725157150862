import React, { useState } from "react";
import { ActionButton, BreadCrumbs, TabButtons, MenuButton } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "../../utils/types";
import { BUTTON_TITLE, trialTeamTabButtons } from "../../utils/constants";
import { TrialTeamStatistics, TrialTeamTab, JuryBox } from "../../components/Pages/TrialTeam";
import "./styles.trialTeam.scss";
import NavigationWrapper from "../../components/NavigationWrapper";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/constants/routes";

type TrialTeamProps = unknown;

const TrialTeam: React.FC<TrialTeamProps> = () => {
  const [activeTab, setActiveTab] = useState<Tab | undefined>(trialTeamTabButtons[0]);
  const navigate = useNavigate();

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case trialTeamTabButtons[0]:
        return <TrialTeamTab />;
      case trialTeamTabButtons[1]:
        return <JuryBox />;
      case trialTeamTabButtons[2]:
        return <TrialTeamStatistics />;
      default:
        return null;
    }
  };

  return (
    <div className='trial-team'>
      <BreadCrumbs
        breadcrumbItems={[
          { url: routes.CREATE_CASE, text: "Case Name" },
          { text: "Trial Team", url: routes.TRIAL_TEAM }
        ]}
      />
      <NavigationWrapper
        title='Trial Team'
        headerChild={
          <div className='button-container'>
            <ActionButton
              className='save-button'
              title={BUTTON_TITLE.UPLOAD_JURY_LIST}
              onClick={() => alert(BUTTON_TITLE.UPLOAD_JURY_LIST)}
              icon={<i className='fa-solid fa-plus'></i>}
            />
            <ActionButton
              title={BUTTON_TITLE.ADD_JUROR}
              onClick={() => navigate(routes.ADD_NEW_JUROR)}
              icon={<FontAwesomeIcon icon={"plus"} />}
            />
            <MenuButton />
          </div>
        }
      >
        <div>
          <TabButtons
            tabs={trialTeamTabButtons}
            activeTab={activeTab || trialTeamTabButtons[0]}
            onTabChange={(tab) => {
              setActiveTab(tab);
            }}
          />

          {renderActiveTabContent()}
        </div>
      </NavigationWrapper>
    </div>
  );
};

export default TrialTeam;
