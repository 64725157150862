import { configureStore } from "@reduxjs/toolkit";
import manageCaseSlice from "../slices/manageCaseSlice";
import casesSlices from "../slices/casesSlices";
import participantsSlice from "../slices/participantsSlice";
import documentsSlice from "../slices/documentsSlice";
import jurorSlice from "../slices/jurorSlice";

export const store = configureStore({
  reducer: {
    manageCase: manageCaseSlice,
    documents: documentsSlice,
    juror: jurorSlice,
    cases: casesSlices,
    participants: participantsSlice
  }
});
