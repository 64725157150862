import React, { useState } from "react";
import { BreadCrumbs } from "../../components";
import { ActionButton } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.dashboard.scss";
import DashboardCard from "../../components/DashboardCard";
import TabButtons from "../../components/TabButtons";
import { DashboardCardType, Tab, TabSectionsPropTypes, TaskButtonProps } from "../../utils/types";
import { BUTTON_TITLE, dashboardCards, dashboardTabButtons, tabSectionsList, TITLES } from "../../utils/constants";
import moment from "moment";
import TaskButton from "../../components/TaskButton/TaskButton";
import { useLocation } from "react-router-dom";
import routes from "../../utils/constants/routes";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState<Tab | undefined>(dashboardTabButtons[0]);
  const [tabSections, setTabSections] = useState<TabSectionsPropTypes[]>(tabSectionsList);
  const [dashboardCardsData, setDashboardCardsData] = useState(dashboardCards);
  const location = useLocation();

  return (
    <div className='dashboard-page'>
      <div>
        <BreadCrumbs
          breadcrumbItems={[
            {
              text: "dashboard",
              url: location.pathname === routes.HOME ? routes.HOME : routes.DASHBOARD
            }
          ]}
        />
      </div>
      <div className='action-row'>
        <span className='date-text'>{moment().format("dddd, MMMM Do, YYYY")}</span>
        <ActionButton
          title={BUTTON_TITLE.ADD_NEW_CASE}
          icon={<FontAwesomeIcon icon={"plus"} />}
          onClick={() => {
            window.location.href = routes.CREATE_CASE;
          }}
        />
      </div>

      <h1 className={"heading1"}>{TITLES.WELCOME_MSG}</h1>
      <div className='cards-row'>
        {dashboardCardsData.map((card: DashboardCardType, index: number) => {
          return (
            <DashboardCard
              key={index?.toString()}
              title={card?.title}
              icon={<i className={card?.iconClassName}></i>}
              count={card?.count}
              className={card?.className}
            />
          );
        })}
      </div>

      <TabButtons
        tabs={dashboardTabButtons}
        activeTab={activeTab || dashboardTabButtons[0]}
        onTabChange={(tab) => {
          setActiveTab(tab);
        }}
      />

      {activeTab &&
        tabSections.find((section: TabSectionsPropTypes) => section.id == activeTab?.id) &&
        tabSections
          .find((section: TabSectionsPropTypes) => section.id == activeTab?.id)
          ?.section?.map((item: TaskButtonProps, index: number) => {
            return (
              <TaskButton
                key={index?.toString()}
                variant={item.variant}
                onClick={item.onClick}
                title={item.title}
                properties={item.properties}
                description={item.description}
              />
            );
          })}
    </div>
  );
};

export default Dashboard;
