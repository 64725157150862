import { Form, useFormikContext } from "formik";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  personalDataPageTabs
} from "../../../../../utils/constants";
import { FinancialInformationFormValueProps, PersonalDataProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import TextInput from "../../../../TextInput";

export const FinancialInformationForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<FinancialInformationFormValueProps>();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.BANK_ACCOUNT_INFORMATION}
          name={FORM_NAMES.BANK_ACCOUNT_INFORMATION}
          value={values.bankAccountInformation}
          errorMessage={errors.bankAccountInformation}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.CREDIT_SCORE}
          name={FORM_NAMES.CREDIT_SCORE}
          value={values.creditScore}
          errorMessage={errors.creditScore}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.INVESTMENT_PREFERENCES}
          name={FORM_NAMES.INVESTMENT_PREFERENCES}
          value={values.investmentPreferences}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.investmentPreferencesCheck}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.INVESTMENT_PREFERENCES_CHECK, e.target.checked)}
        />
        <div className='w-100'>
          <TextInput
            label={FORM_LABELS.MORTGAGE_DETAILS}
            name={FORM_NAMES.MORTGAGE_DETAILS}
            value={values.mortgageDetails}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            onChange={handleChange}
            isSwitch
            parentClassName='w-50'
            checked={values?.mortgageDetailsCheck}
            onSwitchChange={(e) => setFieldValue(FORM_NAMES.MORTGAGE_DETAILS_CHECK, e.target.checked)}
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.AUTO_INFORMATION}
          name={FORM_NAMES.AUTO_INFORMATION}
          value={values.autoInformation}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.autoInformationCheck}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.AUTO_INFORMATION_CHECK, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.BUDGETING_INFORMATION}
          name={FORM_NAMES.BUDGETING_INFORMATION}
          value={values.budgetingInformation}
          errorMessage={errors.budgetingInformation}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.ANY_BANKRUPTCY}
          name={FORM_NAMES.BANKRUPTCY_TEXT}
          value={values.bankruptcyText}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.bankruptcyCheck}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.BANKRUPTCY_CHECK, e.target.checked)}
        />
        <div className='w-100'></div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[7]);
            resetForm();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[5]);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};
