export default {
  HOME: "/",
  DASHBOARD: "/page/dashboard",
  CALENDARS: "/page/calendars",
  CREATE_CASE: "/page/create_case",
  TRIAL_TEAM: "/page/trial-team",
  CASE_EVIDENCE: "/page/create_case/case_evidence",
  ADD_NEW_CANDIDATE: "/page/add-new-candidate",
  ADD_NEW_WITNESS: "/page/add-new-witness",
  ADD_NEW_EXPERTS: "/page/add-new-experts",
  ADD_NEW_PARTICIPANTS: "/page/add-new-participants",
  JUROR_DETAILS: "/page/juror_details",
  DOCUMENTS: "/page/documents",
  MANAGE_CASE: "/page/manage_case",
  CASES_LIST: "/page/cases_list",
  EDIT_NEW_CANDIDATE: "/page/edit-new-candidate",
  EDIT_NEW_WITNESS: "/page/edit-new-witness",
  EDIT_NEW_EXPERTS: "/page/edit-new-experts",
  EDIT_NEW_PARTICIPANTS: "/page/edit-new-participants",
  ADD_NEW_JUROR: "/page/add-new-juror"
};
