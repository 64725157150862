import React, { useCallback, useState } from "react";
import ConfirmationModal from "../ConfirmationModal";
import { ActionsRendererProps } from "../../utils/types";
import { STRINGS } from "../../utils/constants";

const ActionsRenderer: React.FC<ActionsRendererProps> = ({ data, onView, onEdit, onDelete, download = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleView = useCallback(() => {
    onView?.(data);
  }, [data, onView]);

  const handleEdit = useCallback(() => {
    onEdit?.(data);
  }, [data, onEdit]);

  const handleDeleteClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const confirmDelete = useCallback(() => {
    onDelete?.(data);
    setIsModalOpen(false);
  }, [data, onDelete]);

  const cancelDelete = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div className='container-ag-grid-row'>
      <span
        className='icon-button eye-icon-row'
        onClick={handleView}
        data-tooltip-id='tooltip'
        data-tooltip-content='View'
      >
        <i className='fa-regular fa-eye'></i>
      </span>
      {download ? (
        <span
          className='icon-button edit-icon-row'
          onClick={handleEdit}
          data-tooltip-id='tooltip'
          data-tooltip-content='Download'
        >
          <i className='fa-regular fa-cloud-arrow-down'></i>
        </span>
      ) : (
        <span
          className='icon-button edit-icon-row'
          onClick={handleEdit}
          data-tooltip-id='tooltip'
          data-tooltip-content='Edit'
        >
          <i className='fa-regular fa-pen-to-square'></i>
        </span>
      )}
      <span
        className='icon-button delete-icon-row'
        onClick={handleDeleteClick}
        data-tooltip-id='tooltip'
        data-tooltip-content='Delete'
      >
        <i className='fa-regular fa-trash'></i>
      </span>

      {isModalOpen && (
        <ConfirmationModal message={STRINGS.ARE_YOUR_SURE_RECORD} onConfirm={confirmDelete} onCancel={cancelDelete} />
      )}
    </div>
  );
};

export default ActionsRenderer;
