import { Form, useFormikContext } from "formik";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  personalDataPageTabs
} from "../../../../../utils/constants";
import { LegalRecordsValues, PersonalDataProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import TextInput from "../../../../TextInput";

export const LegalRecordsForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<LegalRecordsValues>();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.DRIVING_RECORDS}
          name={FORM_NAMES.DRIVING_RECORDS}
          value={values.driving_records}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.driving_records_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.DRIVING_RECORDS_CHECK, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.CRIMINAL_RECORDS}
          name={FORM_NAMES.CRIMINAL_RECORDS}
          value={values.criminal_records}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.criminal_records_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.CRIMINAL_RECORDS_CHECK, e.target.checked)}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.COURT_RECORDS}
          name={FORM_NAMES.COURT_RECORDS}
          value={values.court_records}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.court_records_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.COURT_RECORDS_CHECK, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.PRIOR_JURY_EXPERIENCE}
          name={FORM_NAMES.PRIOR_JURY_EXPERIENCE}
          value={values.prior_jury_experience}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.prior_jury_experience_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.PRIOR_JURY_EXPERIENCE_CHECK, e.target.checked)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.JURY_QUESTION_RESPONSES}
          name={FORM_NAMES.JURY_QUESTION_RESPONSES}
          value={values.jury_question_responses}
          errorMessage={errors.jury_question_responses}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.WITNESS_PROTECTION_PROGRAM}
          name={FORM_NAMES.WITNESS_PROTECTION_PROGRAM}
          value={values.witness_protection_program}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.witness_protection_program_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.WITNESS_PROTECTION_PROGRAM_CHECK, e.target.checked)}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.NO_CONTACT_OR_RESTRAINING_ORDER}
          name={FORM_NAMES.NO_CONTACT_OR_RESTRAINING_ORDER}
          value={values.no_contact_or_restraining_order}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
          isSwitch
          checked={values?.no_contact_or_restraining_order_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.NO_CONTACT_OR_RESTRAINING_ORDER_CHECK, e.target.checked)}
        />
        <div className='w-100'></div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[3]);
            resetForm();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[1]);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};
