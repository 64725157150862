import React, { useMemo, useCallback } from "react";
import "./RangeSlider.scss";
import { RangeSliderProps } from "../../utils/types";

const RangeSlider: React.FC<RangeSliderProps> = ({ value = "0", min, max, trackColor, onClick }) => {
  const jurorRateNumber = typeof value === "string" ? parseFloat(value.replace("%", "")) : value;
  const percentage = ((jurorRateNumber - min) / (max - min)) * 100;

  const getEmoji = useCallback(() => {
    if (jurorRateNumber < 20)
      return (
        <span className='icon-red cursor-pointer' onClick={onClick}>
          <i className='fa-light fa-face-frown'></i>
        </span>
      );

    if (jurorRateNumber < 50)
      return (
        <span className='icon-orange cursor-pointer ' onClick={onClick}>
          <i className='fa-light fa-face-meh'></i>
        </span>
      );
    return (
      <span className='icon-green cursor-pointer' onClick={onClick}>
        <i className='fa-light fa-face-smile'></i>
      </span>
    );
  }, [jurorRateNumber, onClick]);

  const fetchTrackColor = useMemo(() => {
    if (jurorRateNumber < 20) return "#B61111"; // Red for low values
    if (jurorRateNumber < 50) return "#FF823C"; // Orange for mid values
    return "#137C4E";
  }, [jurorRateNumber]);

  const trackStyle = useMemo(
    () => ({
      background: `linear-gradient(90deg, ${trackColor || fetchTrackColor} ${percentage}%, #F8F9F8 ${percentage}%)`,
      borderRadius: "10px"
    }),
    [trackColor, fetchTrackColor, percentage]
  );

  return (
    <div className={`range-slider-container cursor-pointer`} onClick={onClick}>
      <span className='emoji-icon'>{getEmoji()}</span>
      <input
        className='range-slider-input'
        style={trackStyle}
        type='range'
        value={value}
        min={min}
        max={max}
        disabled
      />
    </div>
  );
};

export default RangeSlider;
