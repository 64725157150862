import React from "react";
import { TextInputPropTypes } from "../../utils/types";
import "./styles.textinput.scss";

/**
 * A text input component with optional label, layout, and error message.
 * Supports two layout options: "column" and "row".
 *
 * @component
 * @example
 * <TextInput
 *   label="Username"
 *   layout="column"
 *   errorMessage="Username is required"
 *   required
 *   placeholder="Enter your username"
 *   isSwitch
 *   checked={true}
 *   onSwitchChange={(e) => handleSwitchChange(e)}
 *   switchLabel={{ on: "Yes", off: "No" }}
 * />
 *
 * @param {TextInputPropTypes} props - The properties for the text input component.
 * @param {string} props.label - The label to display above the input field.
 * @param {"column" | "row"} [props.layout="column"] - The layout of the text input and label. Defaults to "column".
 * @param {string} [props.errorMessage] - The error message to display below the input field. Defaults to a generic required message if not provided.
 * @param {boolean} [props.isSwitch] - Whether to include a switch input alongside the text input.
 * @param {boolean} [props.checked] - The checked state of the switch (if `isSwitch` is true).
 * @param {function} [props.onSwitchChange] - Callback function to handle switch state change.
 * @param {Object} [props.switchLabel] - Labels to display for the switch states (`on` and `off`).
 * @param {React.InputHTMLAttributes<HTMLInputElement>} [props] - Additional properties to pass to the input element, such as `placeholder`, `value`, `onChange`, etc.
 * @returns {JSX.Element} The rendered text input component.
 */
export default function TextInput({
  label,
  layout = "column",
  textAreaClassName,
  errorMessage,
  labelClassName,
  parentClassName,
  rows,
  type = "text",
  isSwitch = false,
  checked = true,
  placeholder = "Type here",
  onSwitchChange,
  icon,
  switchLabel = { on: "Yes", off: "No" },
  currencyText,
  iconClassName = "",
  onClickIcon = () => {},
  ...props
}: TextInputPropTypes) {
  return (
    <div className={`form-group ${parentClassName}`}>
      {layout === "column" ? (
        <>
          <div className='input-field'>
            <label className={`form-label ${labelClassName}`}>
              {label}
              {!!props.required && <span className='required-marker'> *</span>}
            </label>
            {isSwitch && (
              <div className='form-check form-switch'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckChecked'
                  checked={checked}
                  onChange={onSwitchChange}
                />
                <label className='form-label switch' htmlFor='flexSwitchCheckChecked'>
                  {checked ? switchLabel.on : switchLabel.off}
                </label>
              </div>
            )}
          </div>
          <div className='input-group'>
            {type === "textArea" ? (
              <textarea
                id='description'
                name='description'
                className={`form-control ${textAreaClassName}`}
                disabled={!checked}
                rows={rows ? rows : 5}
                maxLength={2000}
                placeholder={placeholder}
              />
            ) : type === "currency" ? (
              <>
                <span className='currency-symbol'>$</span>
                <input
                  className='form-control currency-input'
                  disabled={!checked}
                  maxLength={500}
                  placeholder={placeholder}
                  type='text'
                  {...props}
                />
                {currencyText && <span className='currency-text'>{currencyText}</span>}
              </>
            ) : icon ? (
              <div className='form-control-with-icon'>
                <input className='w-100 mr-1' maxLength={500} placeholder={placeholder} type={type} {...props} />
                <span onClick={onClickIcon} className={`cursor-pointer pr-1 ${iconClassName}`}>
                  {icon}
                </span>
              </div>
            ) : (
              <input
                className='form-control'
                disabled={!checked}
                maxLength={500}
                placeholder={placeholder}
                type={type}
                {...props}
              />
            )}
          </div>
        </>
      ) : (
        <div className='row align-items-center'>
          <div className='col-md-4'>
            <label className='form-label'>
              {label}
              {!!props.required && <span className='required-marker'> *</span>}
            </label>
          </div>
          <div className='col-md-8'>
            <div className='input-group'>
              <input className='form-control' type={type} {...props} />
            </div>
          </div>
        </div>
      )}
      <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
    </div>
  );
}
