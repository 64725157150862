/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectStyle = {
  control: (provided: any) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    border: "1px solid var(--color-border)",
    backgroundColor: "#FFFFFF",
    height: 35,
    minHeight: 35,
    width: "100%",
    boxShadow: "none",
    zIndex: 100,
    "&:hover": {
      borderColor: "var(--color-border)"
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isSelected ? "#FFFFFF" : "#53605d",
    backgroundColor: state.isSelected ? "var(--color-primary-dark)" : state.isFocused ? "#c4ded3" : "#FFFFFF",
    padding: "10px 15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#c4ded3",
      color: "#FFFFFF"
    }
  }),
  placeholder: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 14,
    color: "#53605d"
  }),
  input: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 14,
    color: "#53605d"
  }),
  singleValue: (defaultStyle: any) => ({
    ...defaultStyle,
    fontSize: 14,
    fontWeight: 500,
    color: "var(--color-primary-dark)"
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--color-background-light)",
    borderRadius: "4px",
    padding: "2px 5px"
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "var(--color-primary-dark)",
    fontSize: 14
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "var(--color-error)",
    "&:hover": {
      backgroundColor: "var(--color-error)",
      color: "#FFFFFF"
    }
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "var(--color-placeholder-text)",
    "&:hover": {
      color: "var(--color-placeholder-hover)"
    }
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: "var(--color-error)",
    "&:hover": {
      color: "var(--color-error-hover)"
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginTop: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontSize: 14,
    color: "var(--color-placeholder-text)"
  })
};
