import React, { useState, useCallback } from "react";
import { WrapperComponent } from "../../../WrapperComponent";
import { BUTTON_TITLE, TITLES, ClientColumns, CASE_TABS } from "../../../../utils/constants";
import AgGrid from "../../../AgGrid";
import { ActionButton } from "../../../ActionButton";
import { useNavigate } from "react-router-dom";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import { ParticipantsProps, Participant, PersonInfo } from "../../../../utils/types";
import routes from "../../../../utils/constants/routes";
import PersonInfoModal from "../../../PersonInfoModal";
import { ACTION_COLUMN_STYLE } from "../../../../utils/constants/columns";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/types";
import {
  deleteCandidate,
  deleteWitness,
  deleteExpert,
  deleteParticipant
} from "../../../../redux/slices/participantsSlice";
import { useDispatch } from "react-redux";

const Participants: React.FC<ParticipantsProps> = ({ handleTabChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const { candidates, witnesses, participants, experts } = useSelector((state: RootState) => state.participants);

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleAddNew = (path: string) => {
    navigate(path);
  };

  const handleDelete = useCallback(
    (rowData: Participant, role: string) => {
      switch (role) {
        case "candidate":
          dispatch(deleteCandidate(rowData));
          break;
        case "witness":
          dispatch(deleteWitness(rowData));
          break;
        case "expert":
          dispatch(deleteExpert(rowData));
          break;
        case "participant":
          dispatch(deleteParticipant(rowData));
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  const candidatesClientColumnsList: ColDef[] = [
    ...ClientColumns,
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onDelete={() => handleDelete(params.data as Participant, "candidate")}
          onEdit={() => {
            navigate(routes.EDIT_NEW_CANDIDATE, { state: { participantData: params.data } });
          }}
        />
      )
    }
  ];

  const expertsClientColumnsList: ColDef[] = [
    ...ClientColumns,
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onDelete={() => handleDelete(params.data as Participant, "expert")}
          onEdit={() => {
            handleAddNew(routes.EDIT_NEW_EXPERTS);
          }}
        />
      )
    }
  ];

  const witnessClientColumnsList: ColDef[] = [
    ...ClientColumns,
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onDelete={() => handleDelete(params.data as Participant, "witness")}
          onEdit={() => {
            handleAddNew(routes.EDIT_NEW_WITNESS);
          }}
        />
      )
    }
  ];
  const participantsClientColumnsList: ColDef[] = [
    ...ClientColumns,
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => handleViewPersonInfo(params.data)}
          onDelete={() => handleDelete(params.data as Participant, "participant")}
          onEdit={() => {
            handleAddNew(routes.EDIT_NEW_PARTICIPANTS);
          }}
        />
      )
    }
  ];

  return (
    <div className='d-flex h-100 flex-column'>
      <WrapperComponent
        title={TITLES.CANDIDATES}
        customClass='mb-2'
        buttonText={BUTTON_TITLE.ADD_NEW_CANDIDATE}
        onClick={() => handleAddNew(routes.ADD_NEW_CANDIDATE)}
      >
        <div style={{ height: "350px" }}>
          <AgGrid columnDefs={candidatesClientColumnsList} rowData={candidates} />
        </div>
      </WrapperComponent>

      <WrapperComponent
        title={TITLES.WITNESSES}
        customClass='mt-2 mb-2'
        buttonText={BUTTON_TITLE.ADD_NEW_WITNESS}
        onClick={() => handleAddNew(routes.ADD_NEW_WITNESS)}
      >
        <div style={{ height: "350px" }}>
          <AgGrid columnDefs={witnessClientColumnsList} rowData={witnesses} />
        </div>
      </WrapperComponent>

      <WrapperComponent
        title={TITLES.PARTICIPANTS}
        customClass='mt-2 mb-2'
        buttonText={BUTTON_TITLE.ADD_NEW_PARTICIPANT}
        onClick={() => handleAddNew(routes.ADD_NEW_PARTICIPANTS)}
      >
        <div style={{ height: "350px" }}>
          <AgGrid columnDefs={participantsClientColumnsList} rowData={participants} />
        </div>
      </WrapperComponent>

      <WrapperComponent
        title={TITLES.EXPERTS}
        customClass='mt-2 mb-2'
        buttonText={BUTTON_TITLE.ADD_NEW_EXPERT}
        onClick={() => handleAddNew(routes.ADD_NEW_EXPERTS)}
      >
        <div style={{ height: "350px" }}>
          <AgGrid columnDefs={expertsClientColumnsList} rowData={experts} />
        </div>
      </WrapperComponent>

      <ActionButton
        className='save-button continue-button mt-2 mb-2'
        onClick={() => {
          handleTabChange?.(CASE_TABS.TRIAL_TEAM);
        }}
        title={BUTTON_TITLE.CONTINUE}
      />

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
    </div>
  );
};

export default Participants;
