import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { BreadCrumbs, ActionButton } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { ToggleTabButton, DashboardCards } from "../../components";
import "./styles.interactiveCalendar.scss";
import CreateEventModal from "./components/createEventModal";
import { createEventTypes } from "../../utils/types";
import { calendarSummaryCards, dummyEventsData } from "../../utils/constants";

const InteractiveCalendar = () => {
  const [events, setEvents] = useState(dummyEventsData);
  const [customTitle, setCustomTitle] = useState("");
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const calendarRef = React.createRef<FullCalendar>();
  const [dashboardCards, setDashboardCards] = useState(calendarSummaryCards);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const updateTitle = () => {
        const startDate = calendarApi.view.currentStart;
        const formattedTitle = `${startDate.toLocaleString("default", {
          month: "short",
          year: "numeric"
        })}`;
        setCustomTitle(formattedTitle);
      };

      // Initial title update
      updateTitle();

      // Update title when view changes
      calendarApi.on("datesSet", updateTitle);
    }
  }, [calendarRef]);

  const openModal = () => {
    setIsCreateEventModalOpen(true);
  };

  const handleAddEvent = (event: createEventTypes) => {
    const { title, caseName, date, startTime, endTime } = event;
    setEvents([
      ...events,
      {
        title,
        date,
        caseName,
        start: `${date}T${startTime}`,
        end: `${date}T${endTime}`
      }
    ]);

    setIsCreateEventModalOpen(false);
  };

  const onPreviousClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.prev();
  };

  const onNextClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.next();
  };

  const onWeekClick = () => {
    setCurrentView("timeGridWeek");
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.changeView("timeGridWeek");
  };

  const onMonthClick = () => {
    setCurrentView("dayGridMonth");
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.changeView("dayGridMonth");
  };

  return (
    <div className='interactive-calendar-container'>
      <BreadCrumbs breadcrumbItems={[{ text: "Interactive Calendar", url: "/interactive-calendar" }]} />
      <div className='header-container'>
        <h5 className='custom-title'>{customTitle}</h5>

        <FontAwesomeIcon icon={faChevronLeft} onClick={onPreviousClick} className='navigation-icons' />

        <div className='separator'></div>

        <FontAwesomeIcon icon={faChevronRight} onClick={onNextClick} className='navigation-icons' />

        <div className='view-buttons'>
          <ToggleTabButton isSelected={currentView === "timeGridWeek"} label='Week' onClick={onWeekClick} />
          <ToggleTabButton isSelected={currentView === "dayGridMonth"} label='Month' onClick={onMonthClick} />
        </div>

        <div className='action-buttons-container'>
          <ActionButton title='Add New Task ' icon={<FontAwesomeIcon icon={"plus"} />} onClick={openModal} />
          <ActionButton
            title='Share'
            className='bg-orange'
            icon={<i className='fa-light fa-arrow-up-from-square'></i>}
            onClick={() => alert("Share")}
          />
        </div>
      </div>

      <div className='calendar-cards-container'>
        {dashboardCards.map((card) => (
          <DashboardCards
            key={card.id}
            title={card.title}
            count={card.count}
            icon={<i className={`${card.icon} ${card.className}`}></i>}
            className={card?.className}
          />
        ))}
      </div>
      <FullCalendar
        ref={calendarRef as React.RefObject<FullCalendar>}
        allDaySlot={false}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={false}
        initialView={currentView}
        events={events}
        height={650}
        views={{
          timeGrid: {
            dayHeaderFormat: { day: "2-digit", weekday: "short" }
          }
        }}
        slotLabelFormat={{
          hour: "numeric",
          omitZeroMinute: false,
          meridiem: "short"
        }}
        slotLabelInterval='01:00'
        editable={true}
        selectable={true}
        eventContent={function (arg) {
          return (
            <div className='event-content'>
              <div className='event-title'>{arg.event.title}</div>
              <div className='event-case-name'>{arg.event.extendedProps.caseName}</div>
              <div className='event-time'>
                {arg.event.start?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} -{" "}
                {arg.event.end?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
              </div>
            </div>
          );
        }}
      />

      {isCreateEventModalOpen && (
        <CreateEventModal
          onClose={() => {
            setIsCreateEventModalOpen(false);
          }}
          onAddEvent={handleAddEvent}
        />
      )}
    </div>
  );
};

export default InteractiveCalendar;
