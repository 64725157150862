import * as Yup from "yup";
import { LegalRecordsValues } from "../../../../../utils/types";

export const initialValues: LegalRecordsValues = {
  driving_records: "",
  court_records: "",
  prior_jury_experience: "",
  jury_question_responses: "",
  //old
  criminal_records: "",
  witness_protection_program: "",
  no_contact_or_restraining_order: "",
  // Switch checks
  driving_records_check: false,
  legal_records_check: false,
  criminal_records_check: false,
  court_records_check: false,
  prior_jury_experience_check: false,
  // old
  witness_protection_program_check: false,
  no_contact_or_restraining_order_check: false
};

export const validationSchema = Yup.object().shape({
  driving_records: Yup.string().optional(),
  court_records: Yup.string().optional(),
  prior_jury_experience: Yup.string().optional(),
  jury_question_responses: Yup.string().optional(),
  //old
  criminal_records: Yup.string().optional(),
  witness_protection_program: Yup.string().optional(),
  no_contact_or_restraining_order: Yup.string().optional(),
  // Switch checks
  driving_records_check: Yup.boolean().optional(),
  legal_records_check: Yup.boolean().optional(),
  criminal_records_check: Yup.boolean().optional(),
  court_records_check: Yup.boolean().optional(),
  prior_jury_experience_check: Yup.boolean().optional(),
  // old
  witness_protection_program_check: Yup.boolean().optional(),
  no_contact_or_restraining_order_check: Yup.boolean().optional()
});
