import React, { useCallback } from "react";
import "./styles.scss";
interface IVersionNumber {
  versionNumber: string | null;
  env?: string;
}
export const VersionNumber = ({ versionNumber, env }: IVersionNumber) => {
  const capitalizeFirstLetter = useCallback((e: string) => {
    return e[0].toUpperCase() + e.slice(1);
  }, []);

  return (
    <div className='sidebar-version-number'>
      Build: {versionNumber} {env ? `- ${capitalizeFirstLetter(env)}` : ""}
    </div>
  );
};
