import React from "react";
import { Formik } from "formik";
import ActionButton from "../../ActionButton";
import TextInput from "../../TextInput";
import SelectComponent from "../../SelectComponent";
import AutoCompleteInput from "../../AutoCompleteInput";
import WrapperComponent from "../../WrapperComponent";
import {
  BUTTON_TITLE,
  citiesInCanada,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  statesOptions,
  zipCodesOptions
} from "../../../utils/constants";
import { initialValues, validationSchema } from "./helper";
import { AddNewFormProps } from "../../../utils/types";

const AddNewPerson: React.FC<AddNewFormProps> = ({ isJuror = false, onSubmit }) => {
  return (
    <WrapperComponent>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          resetForm();
        }}
      >
        {({ values, errors, setFieldValue, handleChange, resetForm }) => (
          <>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <TextInput
                label={FORM_LABELS.FIRST_NAME}
                name={FORM_NAMES.FIRST_NAME}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
              />
              <TextInput
                label={FORM_LABELS.LAST_NAME}
                name={FORM_NAMES.LAST_NAME}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <TextInput
                label={FORM_LABELS.EMAIL}
                name={FORM_NAMES.EMAIL}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
              />
              <TextInput
                label={FORM_LABELS.PHONE_NUMBER}
                name={FORM_NAMES.PHONE_NUMBER}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.phoneNumber}
                onChange={handleChange}
                errorMessage={errors.phoneNumber}
              />
            </div>

            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <SelectComponent
                label={FORM_LABELS.CITY}
                name={FORM_NAMES.CITY}
                items={citiesInCanada}
                value={values.city}
                onChange={(e) => setFieldValue(FORM_NAMES.CITY, e.target.value)}
                errorMessage={errors.city}
              />
              <SelectComponent
                label={FORM_LABELS.STATE}
                name={FORM_NAMES.STATE}
                items={statesOptions}
                value={values.state}
                onChange={(e) => setFieldValue(FORM_NAMES.STATE, e.target.value)}
                errorMessage={errors.state}
              />
            </div>

            <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
              <TextInput
                label={FORM_LABELS.COUNTRY}
                name={FORM_NAMES.COUNTRY}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.country}
                onChange={handleChange}
                errorMessage={errors.country}
              />
              <AutoCompleteInput
                onChangeValue={(value) => setFieldValue(FORM_NAMES.ZIP_CODE, value?.value)}
                options={zipCodesOptions}
                optionValue='value'
                optionText='label'
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                label={FORM_LABELS.ZIP_CODE}
                iconType='search'
                errorMessage={errors.zipCode}
              />
            </div>

            {isJuror && (
              <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2 '>
                <TextInput
                  label={FORM_LABELS.SEAT_NO}
                  name={FORM_NAMES.SEAT_NO}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  value={values.seatNo}
                  type={FIELD_TYPES.NUMBER}
                  onChange={handleChange}
                  errorMessage={errors.seatNo}
                  parentClassName='w-48'
                />
              </div>
            )}

            <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
              <ActionButton
                title={BUTTON_TITLE.SAVE}
                className='save-button'
                onClick={() => {
                  onSubmit?.(values);
                  resetForm();
                }}
              />
              <ActionButton
                title={BUTTON_TITLE.CANCEL}
                onClick={() => {
                  resetForm?.();
                }}
                className='cancel-button'
              />
            </div>
          </>
        )}
      </Formik>
    </WrapperComponent>
  );
};

export default AddNewPerson;
