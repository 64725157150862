import * as Yup from "yup";

export const socialMediaValidationSchema = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  facebook: Yup.string().url("Invalid URL"),
  reddit: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  website_blog_urls: Yup.string().url("Invalid URL").required("Website/Blog URL is required"),
  linkedin: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL")
});

export const initialSocialMediaValues = {
  twitter: "",
  facebook: "",
  reddit: "",
  tiktok: "",
  website_blog_urls: "",
  linkedin: "",
  instagram: "",
  youtube: ""
};
