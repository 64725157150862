/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import "./styles.scss";

interface SearchInputProps {
  handleSearch?: (str: string) => void;
  searchPlaceholder?: string;
}

const SearchInput = ({ handleSearch, searchPlaceholder = "" }: SearchInputProps) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchStr, setSearchStr] = useState("");

  /**
   * Called when the user inputs value into the input box
   *
   * @param e - Event data
   * @returns Void
   */
  const handleChangeAutoSuggestInput = (e: any) => {
    if (e.target.value === undefined) {
      return;
    }
    setSearchStr(e.target.value);
  };

  /**
   * Called when the user selectes a value from the suggestions list
   *
   * @param event - Event data.
   * @param suggestion - Suggestion object
   * @param suggestionValue - Suggestion string value
   * @returns Void
   */
  const onSuggestionSelected = (event: any, { suggestionValue, method }: any) => {
    setSearchStr(suggestionValue ?? "");
    if (method === "click") {
      onSubmitForm(event, suggestionValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }: any) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    getSuggestions("");
  };

  const getSearchHistory = () => {
    const history = JSON.parse(localStorage.getItem("searchHistory") || "[]");
    return history;
  };

  const addSearchHistory = (search: string) => {
    let history = JSON.parse(localStorage.getItem("searchHistory") || "[]");
    history = history.filter((item: string) => item !== search);
    history.unshift(search);
    if (history.length > 10) {
      history.length = 10;
    }
    localStorage.setItem("searchHistory", JSON.stringify(history));
  };

  const getSuggestions = (value: any) => {
    const list = getSearchHistory();
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return setSuggestions(
      inputLength === 0 ? list : list.filter((lang: string) => lang.toLowerCase().slice(0, inputLength) === inputValue)
    );
  };
  const onClickClearSearch = () => {
    setSearchStr("");
  };
  const onSubmitForm = (event?: any, _searchStr?: string) => {
    const searchTxt = _searchStr ?? searchStr;
    if (searchTxt.length > 0) {
      event && event.preventDefault();
      const _searchTxt = searchTxt.replaceAll("/", " ");
      addSearchHistory(_searchTxt);
      handleSearch && handleSearch(_searchTxt);
    }
  };
  return (
    <form className='app-search' onSubmit={onSubmitForm}>
      <div className='position-relative'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(item: string) => item}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={(value: any, reason: any) => {
            return value.trim().length > 0 || reason === "input-focused" || reason === "render";
          }}
          renderSuggestion={(item: any, { isHighlighted }: any) => {
            return (
              <div
                key={item}
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "5px",
                  cursor: "pointer"
                }}
              >
                {item}
              </div>
            );
          }}
          renderSuggestionsContainer={({ containerProps, children }: any) => {
            containerProps.className = `${containerProps.className} position-absolute w-100`;
            return <div {...containerProps}>{children}</div>;
          }}
          inputProps={{
            className: "form-control search-autocomplete",
            id: `autosuggest-search`,
            name: "autosuggest-search",
            value: searchStr,
            placeholder: searchPlaceholder,
            onFocus: (e: any) => {
              if (e.target?.value?.length > 0) {
                e.target.select();
              }
            },
            onChange: (e: any) => {
              handleChangeAutoSuggestInput(e);
            }
          }}
          renderInputComponent={(inputProps: any) => (
            <div>
              <input {...inputProps} />
              <a onClick={onSubmitForm}>
                <span className='fa fa-regular fa-magnifying-glass'></span>
              </a>
              {inputProps.value?.length > 0 && (
                <a onClick={onClickClearSearch}>
                  <span className='fa fa-regular fa-close block close-icon'></span>
                </a>
              )}
            </div>
          )}
        />
      </div>
    </form>
  );
};

export default SearchInput;
