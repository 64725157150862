import React, { useState } from "react";
import { WrapperComponent } from "../../../WrapperComponent";
import { Formik } from "formik";
import { initialValues, validationSchema } from "./helper";
import NewCaseForm from "./NewCaseForm";
import CourtDates from "./CourtDates";
import { CreateNewCaseProps, NewCaseFormValues } from "../../../../utils/types";
import "./styles.CreateNewCase.scss";
import TabButtons from "../../../TabButtons";
import { manageCaseTabButtons } from "../../../../utils/constants";

const CreateNewCase: React.FC<CreateNewCaseProps> = ({ handleTabChange, caseData }) => {
  const [activeTab, setActiveTab] = useState(manageCaseTabButtons[0]); // Use trialTeamTabButtons for the tabs

  const renderTabContent = () => {
    switch (activeTab) {
      case manageCaseTabButtons[0]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewCaseForm handleTabChange={handleTabChange} caseData={caseData} />
            </Formik>
          </WrapperComponent>
        );
      case manageCaseTabButtons[1]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <CourtDates handleTabChange={handleTabChange} />
            </Formik>
          </WrapperComponent>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabButtons tabs={manageCaseTabButtons} activeTab={activeTab} onTabChange={(tab) => setActiveTab(tab)} />
      {renderTabContent()}
    </>
  );
};

export default CreateNewCase;
