import { Formik } from "formik";
import { PersonalDataProps, PoliticalAndSocialFormValueProps } from "../../../../../utils/types";
import WrapperComponent from "../../../../WrapperComponent";
import { initialValues, validationSchema } from "./helpers";
import { PoliticalAndSocialDetailsForm } from "./PoliticalAndSocialDetailsForm";

const PoliticalAndSocialDetails: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title='Political & Social Information'>
      <Formik<PoliticalAndSocialFormValueProps>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <PoliticalAndSocialDetailsForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};

export default PoliticalAndSocialDetails;
