import React, { useMemo } from "react";
import { WrapperComponent } from "../../../../WrapperComponent";
import { Formik } from "formik";
import {
  demographicsValidationSchema,
  initialDemographicsValues,
  initialLegalAndOtherRecordsValues,
  initialSocialMediaValues,
  initialValues,
  legalAndOtherRecordsValidationSchema,
  socialMediaValidationSchema,
  validationSchema
} from "./helper";
import { CreateNewClientProps } from "../../../../../utils/types";
import NewClientFormBasic from "./NewClientFormBasic";
import NewClientDemographics from "./NewClientDemographics";
import NewClientFormLegal from "./NewClientFormLegal";
import NewClientFormSocial from "./NewClientFormSocial";
import { CASE_TABS, TITLES } from "../../../../../utils/constants";

const CreateNewClient: React.FC<CreateNewClientProps> = ({
  activeTab,
  photoLabel = "",
  subheader = "",
  handleTabChange,
  data
}) => {
  const allInitialValues = useMemo(() => {
    switch (activeTab) {
      case CASE_TABS.BASIC:
        return initialValues;
      case CASE_TABS.DEMOGRAPHICS:
        return initialDemographicsValues;
      case CASE_TABS.LEGAL:
        return initialLegalAndOtherRecordsValues;
      case CASE_TABS.SOCIAL_MEDIA:
        return initialSocialMediaValues;
      default:
        return initialValues;
    }
  }, [activeTab]);

  const validationSchemas = useMemo(() => {
    switch (activeTab) {
      case CASE_TABS.BASIC:
        return validationSchema;
      case CASE_TABS.DEMOGRAPHICS:
        return demographicsValidationSchema;
      case CASE_TABS.LEGAL:
        return legalAndOtherRecordsValidationSchema;
      case CASE_TABS.SOCIAL_MEDIA:
        return socialMediaValidationSchema;
      default:
        return validationSchema;
    }
  }, [activeTab]);

  const RenderActiveTabContent = () => {
    switch (activeTab) {
      case CASE_TABS.BASIC:
        return <NewClientFormBasic photoLabel={photoLabel} handleTabChange={handleTabChange} data={data} />;
      case CASE_TABS.DEMOGRAPHICS:
        return <NewClientDemographics handleTabChange={handleTabChange} />;
      case CASE_TABS.LEGAL:
        return <NewClientFormLegal handleTabChange={handleTabChange} />;
      case CASE_TABS.SOCIAL_MEDIA:
        return <NewClientFormSocial handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  return (
    <WrapperComponent title={CASE_TABS.BASIC === activeTab ? subheader : TITLES[activeTab]}>
      <Formik
        initialValues={allInitialValues}
        validationSchema={validationSchemas}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {() => <>{RenderActiveTabContent()}</>}
      </Formik>
    </WrapperComponent>
  );
};

export default CreateNewClient;
