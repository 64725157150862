import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddNewFormValues, Juror } from "../../utils/types";
import { jurorGridData } from "../../utils/constants";
import { JurorState } from "../types";

const initialState: JurorState = {
  juror: jurorGridData
};

const jurorSlice = createSlice({
  name: "juror",
  initialState,
  reducers: {
    addJuror: (state, action: PayloadAction<AddNewFormValues>) => {
      state.juror.unshift(action.payload);
    },
    deleteJuror: (state, action: PayloadAction<string>) => {
      state.juror = state.juror.filter((doc) => doc.email !== action.payload);
    },
    setJuror: (state, action: PayloadAction<Juror[]>) => {
      state.juror = action.payload;
    },
    updateJuror: (state, action: PayloadAction<Juror>) => {
      const index = state.juror.findIndex((j) => j.email === action.payload.email);
      if (index !== -1) {
        state.juror[index] = action.payload;
      }
    }
  }
});

export const { addJuror, deleteJuror, setJuror, updateJuror } = jurorSlice.actions;

export default jurorSlice.reducer;
