import React, { useState, useCallback, useMemo } from "react";
import "./styles.scss";
import { FIELD_TYPES, FORM_PLACEHOLDERS, RATING_LABELS, RATING_VALUES } from "../../utils/constants";
import TextInput from "../TextInput";
import { RatingFieldProps, RatingValue } from "../../utils/types";

const RatingField: React.FC<RatingFieldProps> = ({
  label,
  initialRating = RATING_VALUES[0] || "",
  value = "",
  onRatingChange,
  onCommentChange
}) => {
  const [selectedRating, setSelectedRating] = useState<RatingValue | string>(initialRating);
  const [comment, setComment] = useState<string | number>(value);

  const handleRatingClick = useCallback(
    (rating: RatingValue) => {
      setSelectedRating(rating);
      onRatingChange(rating);
    },
    [onRatingChange]
  );

  const renderRatingButtons = useMemo(
    () =>
      RATING_VALUES.map((rating) => (
        <div
          key={rating}
          className={`rating-button ${selectedRating === rating ? "selected" : ""}`}
          onClick={() => handleRatingClick(rating)}
        >
          {rating}
        </div>
      )),
    [selectedRating, handleRatingClick]
  );

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newComment = e.target.value;
      setComment(newComment);
      onCommentChange(newComment);
    },
    [onCommentChange]
  );

  return (
    <div className='overall-rating'>
      <label className='label-rating'>{label}</label>
      <div className='rating-container'>
        <span className='rating-label right'>{RATING_LABELS.negative}</span>
        {renderRatingButtons}
        <span className='rating-label left'>{RATING_LABELS.positive}</span>
      </div>
      <TextInput
        type={FIELD_TYPES.TEXT_AREA}
        placeholder={FORM_PLACEHOLDERS.COMMENT_HERE}
        value={comment}
        onChange={handleCommentChange}
      />
    </div>
  );
};

export default RatingField;
