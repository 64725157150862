import { Formik } from "formik";
import { FinancialInformationFormValueProps, PersonalDataProps } from "../../../../../utils/types";
import WrapperComponent from "../../../../WrapperComponent";
import { FinancialInformationForm } from "./FinancialInformationForm";
import { financialInformationInitialValues, financialInformationValidationSchema } from "./helper";

const FinancialInformation: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title='Financial Information'>
      <Formik<FinancialInformationFormValueProps>
        initialValues={financialInformationInitialValues}
        validationSchema={financialInformationValidationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <FinancialInformationForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};

export default FinancialInformation;
