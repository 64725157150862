import React from "react";
import { BUTTON_TITLE, CASE_TABS } from "../../../../utils/constants";
import { ActionButton } from "../../../ActionButton";
import DateInput from "../../../DateInput";
import { Form, useFormikContext } from "formik";
import { CreateNewCaseProps, NewCaseFormValues } from "../../../../utils/types";
import { FORM_LABELS, FORM_PLACEHOLDERS, FORM_NAMES } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";

const CourtDates: React.FC<CreateNewCaseProps> = ({ handleTabChange }) => {
  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormikContext<NewCaseFormValues>();
  const navigate = useNavigate();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.TRIAL_START_DATE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.trialStartDate}
            parentClassName='w-50'
            value={values?.trialStartDate}
            onDateChange={(date) => setFieldValue(FORM_NAMES.TRIAL_START_DATE, date)}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.FILING_DATE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.filingDate}
            parentClassName='w-50'
            value={values?.filingDate}
            onDateChange={(date) => setFieldValue(FORM_NAMES.FILING_DATE, date)}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.DISCOVERY_DEADLINE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.discoveryDeadline}
            parentClassName='w-50'
            value={values?.discoveryDeadline}
            onDateChange={(date) => setFieldValue(FORM_NAMES.DISCOVERY_DEADLINE, date)}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          // disabled={!values?.caseName || !values?.courtCaseNumber}
          onClick={() => {
            handleTabChange?.(CASE_TABS.PARTICIPANTS);
            resetForm();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};

export default CourtDates;
