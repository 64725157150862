import React, { useEffect } from "react";
import { Form, useFormikContext } from "formik";
import { ClientInformationFormValues, NewClientFormBasicProps } from "../../../../../utils/types";
import BasicInfo from "../../../BasicInfo";
import { FORM_NAMES } from "../../../../../utils/constants";

const NewClientFormBasic: React.FC<NewClientFormBasicProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues } =
    useFormikContext<ClientInformationFormValues>();
  useEffect(() => {
    if (props?.data) {
      const { firstName, lastName, email, city, phone } = props.data;

      setValues((prevValues) => ({
        ...prevValues,
        firstName: firstName || prevValues.firstName,
        lastName: lastName || prevValues.lastName,
        email: email || prevValues.email,
        city: city || prevValues.city,
        phone: phone || prevValues.phone
      }));
    }
  }, [props?.data, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <BasicInfo
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        photoLabel={props?.photoLabel}
        handleTabChange={props?.handleTabChange}
        resetForm={resetForm}
        data={props?.data}
      />
    </Form>
  );
};

export default NewClientFormBasic;
