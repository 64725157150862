import React from "react";
import { ActionButtonPropTypes } from "../../utils/types";
import "./styles.button.scss";

export const ActionButton = ({ className = "", icon, title, onClick, disabled = false }: ActionButtonPropTypes) => {
  return (
    <button
      type='button'
      className={`button ${className} ${disabled ? "disabled" : ""}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {icon || ""}
      {title || ""}
    </button>
  );
};

export default ActionButton;
