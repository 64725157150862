import React from "react";
import { Formik } from "formik";
import { initialValues, validationSchema } from "./helper";
import AddCase from "./AddCase";

const AddNewCase: React.FC = () => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        // Handle form submission here
      }}
    >
      <AddCase />
    </Formik>
  );
};

export default AddNewCase;
