/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import React from "react";
import "./styles.taskbutton.scss";
import { TaskButtonProps } from "../../utils/types";

/**
 * A component that renders a task button with a customizable title, properties, and description.
 *
 * @component
 * @param {TaskButtonProps} props - The props for the TaskButton component.
 * @param {string} props.variant - A CSS variant to style the button.
 * @param {string} props.title - The title to display at the top of the button.
 * @param {Array<{ property_name: string, property_value: string }>} props.properties - An array of properties to display in the middle content.
 * @param {string} props.description - The description to display at the bottom of the button.
 * @param {() => void} props.onClick - A callback function triggered when the button is clicked.
 *
 * @returns {JSX.Element} A rendered task button with the specified title, properties, and description.
 */
export const TaskButton = ({ variant, title, properties, description, onClick }: TaskButtonProps) => {
  return (
    <div className={`tb-wrapper ${variant}`} data-bs-theme='light' onClick={onClick}>
      <div className='left-bar' />
      <div className='tb-content'>
        <div className='top-content'>
          <div className='title'>{title}</div>
        </div>
        <div className='middle-content'>
          {properties.slice(0, 4).map((property, index) => {
            return (
              <div className={`middle-item ${properties.slice(0, 4).length === index + 1 ? "last" : ""}`} key={index}>
                <div className='property-name'>{property.property_name}</div>
                <div className='property-value'>{property.property_value}</div>
              </div>
            );
          })}
        </div>
        <div className='bottom-content'>{description}</div>
      </div>
    </div>
  );
};

export default TaskButton;
