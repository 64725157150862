/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import TextInput from "../../../TextInput";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../SelectComponent";
import { BUTTON_TITLE, CASE_TABS, CaseStatuses } from "../../../../utils/constants";
import { ActionButton } from "../../../ActionButton";
import { Form, useFormikContext } from "formik";
import { CreateNewCaseProps, NewCaseFormValues } from "../../../../utils/types";
import { FORM_LABELS, FORM_PLACEHOLDERS, FORM_NAMES } from "../../../../utils/constants";

const NewCaseForm: React.FC<CreateNewCaseProps> = ({ handleTabChange, caseData }) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues } =
    useFormikContext<NewCaseFormValues>();
  const navigate = useNavigate();

  useEffect(() => {
    if (caseData) {
      setValues({
        ...values,
        caseName: caseData.caseName,
        courtCaseNumber: caseData.caseNumber
      });
    }
  }, [caseData]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CASE_NAME}
          name={FORM_NAMES.CASE_NAME}
          errorMessage={errors?.caseName}
          value={values?.caseName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.COURT_CASE_NUMBER}
          name={FORM_NAMES.COURT_CASE_NUMBER}
          type='number'
          errorMessage={errors?.courtCaseNumber}
          value={values?.courtCaseNumber}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CLIENT_NAME}
          name={FORM_NAMES.CLIENT_NAME}
          errorMessage={errors?.clientName}
          value={values?.clientName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <SelectComponent
          label={FORM_LABELS.CASE_STATUS}
          name={FORM_NAMES.CASE_STATUS}
          errorMessage={errors?.caseStatus}
          items={CaseStatuses}
          value={values?.caseStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.CASE_STATUS, e.target.value)}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.COURT_JURISDICTION}
          name={FORM_NAMES.COURT_JURISDICTION}
          errorMessage={errors?.courtJurisdiction}
          value={values?.courtJurisdiction}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.CASE_TYPE}
          name={FORM_NAMES.CASE_TYPE}
          errorMessage={errors?.caseType}
          value={values?.caseType}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.DEFENDANT_NAME}
          name={FORM_NAMES.DEFENDANT_NAME}
          errorMessage={errors?.defendantName}
          value={values?.defendantName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.OPPOSING_COUNSEL_NAME}
          name={FORM_NAMES.OPPOSING_COUNSELS_NAME}
          errorMessage={errors?.opposingCounselsName}
          value={values?.opposingCounselsName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleTabChange?.(CASE_TABS.PARTICIPANTS);
            resetForm();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};

export default NewCaseForm;
