// helper.ts

import * as Yup from "yup";
import { FinancialInformationFormValueProps } from "../../../../../utils/types";

export const financialInformationValidationSchema = Yup.object().shape({
  bankAccountInformation: Yup.string().optional(),
  creditScore: Yup.string().optional(),
  investmentPreferences: Yup.string().optional(),
  investmentPreferencesCheck: Yup.boolean().optional(),
  autoInformation: Yup.string().optional(),
  mortgageDetails: Yup.string().optional(),
  mortgageDetailsCheck: Yup.boolean().optional(),
  budgetingInformation: Yup.string().optional(),
  autoInformationCheck: Yup.boolean().optional(),
  bankruptcyCheck: Yup.boolean().optional(),
  bankruptcyText: Yup.string().optional()
});

export const financialInformationInitialValues: FinancialInformationFormValueProps = {
  bankAccountInformation: "",
  creditScore: "",
  investmentPreferences: "",
  investmentPreferencesCheck: false,
  autoInformation: "",
  mortgageDetails: "",
  mortgageDetailsCheck: false,
  budgetingInformation: "",
  autoInformationCheck: false,
  bankruptcyCheck: false,
  bankruptcyText: ""
};
