import React, { useCallback, useState } from "react";
import WrapperComponent from "../../components/WrapperComponent";
import NavigationWrapper from "../../components/NavigationWrapper";
import { ActionButton, BreadCrumbs } from "../../components";
import { ACTION_COLUMN_STYLE, baseCaseColumns, BUTTON_TITLE, TITLES } from "../../utils/constants";
import AgGrid from "../../components/AgGrid";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import ActionsRenderer from "../../components/AgGrid/ActionGridRenderer";
import routes from "../../utils/constants/routes";
import { CaseListData } from "../../utils/types/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/types";
import { deleteCase } from "../../redux/slices/casesSlices";
import { toast } from "react-toastify";

const CaseList = () => {
  const caseList = useSelector((state: RootState) => state.cases.caseList);
  const [caseListData, setCaseListData] = useState<CaseListData[]>(caseList);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleDeleteCase = useCallback(
    (docToDelete: CaseListData) => {
      setCaseListData((prevData) => prevData.filter((doc) => doc.caseNumber !== docToDelete.caseNumber));
      dispatch(deleteCase(docToDelete.caseNumber));
    },
    [dispatch]
  );

  const handleEdit = useCallback(
    (caseData: CaseListData) => {
      navigate(routes.MANAGE_CASE, { state: caseData });
    },
    [navigate]
  );

  const CaseListColumns: ColDef[] = [
    ...baseCaseColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onDelete={() => handleDeleteCase(params.data)}
          onEdit={() => handleEdit(params.data)}
        />
      )
    }
  ];

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs breadcrumbItems={[{ text: "Cases", url: routes.CASES_LIST }]} />
      <NavigationWrapper
        title={TITLES.CASES}
        headerChild={
          <ActionButton
            title={BUTTON_TITLE.ADD_NEW_CASE}
            onClick={() => (window.location.href = routes.CREATE_CASE)}
            className='save-button bg-blue'
          />
        }
      >
        <WrapperComponent>
          <div className='d-flex' style={{ height: "60vh" }}>
            <AgGrid columnDefs={CaseListColumns} rowData={caseListData} />
          </div>
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default CaseList;
