export const getDonughtLabelStyles = (conClassName: string = "") => {
  if (conClassName === "modal-donught") {
    return {
      usePointStyle: true,
      pointStyle: "rect",
      boxWidth: 5,
      padding: 10,
      borderRadius: 10
    };
  }

  return {
    usePointStyle: true,
    pointStyle: "rect",
    boxWidth: 10,
    padding: 20,
    borderRadius: 20
  };
};
