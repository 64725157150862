/* eslint-disable react-hooks/exhaustive-deps */
import { Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { JurorInformationFormValues, PersonalDataProps } from "../../../../../utils/types";
import ImageAttachment from "../../../../ImageAttachment";
import {
  BUTTON_TITLE,
  citiesInCanada,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  genderOptions,
  navCards,
  personalDataPageTabs,
  preferredTitleOptions,
  pronounsOptions,
  relationshipStatusOptions,
  sexOptions,
  statesOptions,
  timeZoneOptions,
  zipCodesOptions
} from "../../../../../utils/constants";
import TextInput from "../../../../TextInput";
import SelectComponent from "../../../../SelectComponent";
import AutoCompleteInput from "../../../../AutoCompleteInput";
import ActionButton from "../../../../ActionButton";
import { useLocation, useNavigate } from "react-router-dom";

const BasicInformationForm: React.FC<PersonalDataProps> = ({ handleTabChange, setActiveCard }) => {
  const formik = useFormikContext<JurorInformationFormValues>();
  const navigate = useNavigate();
  const location = useLocation();
  const jurorData = location.state;

  useEffect(() => {
    if (jurorData) {
      setValues({
        ...values,
        firstName: jurorData.firstName,
        lastName: jurorData.lastName,
        seatNo: jurorData.seatNo,
        phoneNumber: jurorData.phoneNumber,
        email: jurorData.email,
        country: jurorData.country
      });
    }
  }, [jurorData]);

  if (!formik) {
    console.error("Formik context is undefined : ", { formik });
    return null;
  }

  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <>
        <div className='d-flex flex-row w-100 sm-flex-wrap justify-content-between'>
          <div className='client-photo-section sm-flex-wrap '>
            <ImageAttachment
              label={FORM_LABELS.JURY_PHOTO}
              onFileChange={(file) => setFieldValue("clientPhoto", file)}
              maxFiles={1}
              acceptVideos={false}
            />
          </div>

          <div className='client-info-section d-flex flex-column gap-1'>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <div className='w-73'>
                <TextInput
                  label={FORM_LABELS.FIRST_NAME}
                  name={FORM_NAMES.FIRST_NAME}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  value={values.firstName}
                  onChange={handleChange}
                  errorMessage={errors.firstName}
                />
              </div>
              <SelectComponent
                label={FORM_LABELS.PREFERRED_TITLE}
                name={FORM_NAMES.PREFERRED_TITLE}
                items={preferredTitleOptions}
                value={values.preferredTitle}
                onChange={(e) => setFieldValue(FORM_NAMES.PREFERRED_TITLE, e.target.value)}
                errorMessage={errors.preferredTitle}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <div className='w-73'>
                <TextInput
                  label={FORM_LABELS.LAST_NAME}
                  name={FORM_NAMES.LAST_NAME}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  value={values.lastName}
                  onChange={handleChange}
                  errorMessage={errors.lastName}
                />
              </div>
              <SelectComponent
                label={FORM_LABELS.PRONOUNS}
                name={FORM_NAMES.PRONOUNS}
                items={pronounsOptions}
                value={values.pronouns}
                onChange={(e) => setFieldValue(FORM_NAMES.PRONOUNS, e.target.value)}
                errorMessage={errors.pronouns}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <div className='w-73'>
                <TextInput
                  label={FORM_LABELS.NICKNAME}
                  name={FORM_NAMES.NICKNAME}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  value={values.nickname}
                  onChange={handleChange}
                />
              </div>
              <TextInput
                label={FORM_LABELS.AGE}
                name={FORM_NAMES.AGE}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                type={FIELD_TYPES.NUMBER}
                value={values.age}
                onChange={handleChange}
                errorMessage={errors.age}
              />
            </div>
          </div>
        </div>
        <div className='d-flex flex-row gap-4 sm-flex-wrap mb-2'>
          <SelectComponent
            label={FORM_LABELS.SEX}
            name={FORM_NAMES.SEX}
            items={sexOptions}
            value={values.sex}
            onChange={(e) => setFieldValue(FORM_NAMES.SEX, e.target.value)}
            errorMessage={errors.sex}
          />
          <SelectComponent
            label={FORM_LABELS.GENDER}
            name={FORM_NAMES.GENDER}
            items={genderOptions}
            value={values.gender}
            onChange={(e) => setFieldValue(FORM_NAMES.GENDER, e.target.value)}
            errorMessage={errors.gender}
          />
        </div>
        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <SelectComponent
            label={FORM_LABELS.RELATIONSHIP_STATUS_OPTION}
            name={FORM_NAMES.RELATIONSHIP_STATUS_OPTION}
            items={relationshipStatusOptions}
            value={values.relationshipStatusOption}
            onChange={(e) => setFieldValue(FORM_NAMES.RELATIONSHIP_STATUS_OPTION, e.target.value)}
            errorMessage={errors.relationshipStatusOption}
          />
          <TextInput
            label={FORM_LABELS.CHILDREN}
            name={FORM_NAMES.CHILDREN_TEXT}
            isSwitch
            type={FIELD_TYPES.NUMBER}
            value={values.childrenText}
            checked={values?.children}
            onChange={handleChange}
            onSwitchChange={(e) => setFieldValue(FORM_NAMES.CHILDREN, e.target.checked)}
          />
        </div>
        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <TextInput
            label={FORM_LABELS.ADDRESS}
            name={FORM_NAMES.ADDRESS}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.address}
            onChange={handleChange}
            errorMessage={errors.address}
          />
          <TextInput
            label={FORM_LABELS.ADDITIONAL_ADDRESS}
            name={FORM_NAMES.ADDITIONAL_ADDRESS}
            placeholder={FORM_PLACEHOLDERS.ADDITIONAL_ADDRESS}
            value={values.addressAdditional}
            onChange={handleChange}
          />
        </div>

        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <SelectComponent
            label={FORM_LABELS.CITY}
            name={FORM_NAMES.CITY}
            items={citiesInCanada}
            value={values.city}
            onChange={(e) => setFieldValue(FORM_NAMES.CITY, e.target.value)}
            errorMessage={errors.city}
          />
          <SelectComponent
            label={FORM_LABELS.STATE}
            name={FORM_NAMES.STATE}
            items={statesOptions}
            value={values.state}
            onChange={(e) => setFieldValue(FORM_NAMES.STATE, e.target.value)}
            errorMessage={errors.state}
          />
        </div>

        <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
          <TextInput
            label={FORM_LABELS.COUNTRY}
            name={FORM_NAMES.COUNTRY}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.country}
            onChange={handleChange}
            errorMessage={errors.country}
          />
          <AutoCompleteInput
            onChangeValue={(value) => setFieldValue(FORM_NAMES.ZIP_CODE, value?.value)}
            options={zipCodesOptions}
            optionValue='value'
            optionText='label'
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            label={FORM_LABELS.ZIP_CODE}
            iconType='search'
            errorMessage={errors.zipCode}
          />
        </div>

        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <TextInput
            label={FORM_LABELS.PHONE_NUMBER}
            name={FORM_NAMES.PHONE_NUMBER}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.phoneNumber}
            onChange={handleChange}
            errorMessage={errors.phoneNumber}
          />
          <TextInput
            label={FORM_LABELS.ALT_PHONE_NUMBER}
            name={FORM_NAMES.ALT_PHONE_NUMBER}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.altPhoneNumber}
            onChange={handleChange}
            errorMessage={errors.altPhoneNumber}
          />
        </div>
        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <TextInput
            label={FORM_LABELS.EMAIL}
            name={FORM_NAMES.EMAIL}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.email}
            onChange={handleChange}
            errorMessage={errors.email}
          />
          <TextInput
            label={FORM_LABELS.ANY_MEDICAL_CONDITIONS}
            name={FORM_NAMES.MEDICAL_CONDITION_TEXT}
            isSwitch
            type={FIELD_TYPES.TEXT}
            value={values.medicalConditionText}
            checked={values?.anyMedicalConditions}
            onChange={handleChange}
            onSwitchChange={(e) => setFieldValue(FORM_NAMES.ANY_MEDICAL_CONDITIONS, e.target.checked)}
          />
        </div>

        <div className='d-flex flex-row gap-4 sm-flex-wrap'>
          <TextInput
            label={FORM_LABELS.SEAT_NO}
            name={FORM_NAMES.SEAT_NO}
            placeholder={FORM_PLACEHOLDERS.DEFAULT}
            value={values.seatNo}
            type={FIELD_TYPES.NUMBER}
            onChange={handleChange}
            errorMessage={errors.seatNo}
          />
          <SelectComponent
            label={FORM_LABELS.TIME_ZONE}
            name={FORM_NAMES.TIME_ZONE}
            items={timeZoneOptions}
            value={values.timeZone}
            onChange={(e) => setFieldValue(FORM_NAMES.TIME_ZONE, e.target.value)}
            errorMessage={errors.timeZone}
          />
        </div>

        <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
          <ActionButton
            title={BUTTON_TITLE.SAVE}
            className='save-button'
            onClick={() => {
              handleTabChange?.(personalDataPageTabs[1]);
              resetForm();
            }}
          />
          <ActionButton
            title={BUTTON_TITLE.CANCEL}
            onClick={() => {
              setActiveCard?.(navCards[0]);
              navigate(-1);
              resetForm();
            }}
            className='cancel-button'
          />
        </div>
      </>
    </Form>
  );
};

export default BasicInformationForm;
