import * as Yup from "yup";
import { AddNewCaseFormValues } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  caseName: Yup.string().optional(),
  courtCaseNumber: Yup.number().optional()
});

export const initialValues: AddNewCaseFormValues = {
  caseName: "",
  courtCaseNumber: ""
};
