import { TabSectionsPropTypes, TaskButtonProps } from "../types";
import routes from "./routes";

export const tabSection1: TaskButtonProps[] = [
  {
    title: "State Of NC Vs Johnson",
    variant: "info",
    description: "The composition of the jury has been approved.",
    onClick: () => {
      window.location.href = routes.CREATE_CASE;
    },
    properties: [
      {
        property_name: "Court Case #",
        property_value: "122556",
        property_format: ""
      },
      {
        property_name: "Court Jurisdiction",
        property_value: "Appellate",
        property_format: ""
      },
      {
        property_name: "Client's Name",
        property_value: "Johnson",
        property_format: ""
      },
      {
        property_name: "Trial Date",
        property_value: "07/17/2024",
        property_format: ""
      }
    ]
  }
];
export const tabSection2: TaskButtonProps[] = [
  {
    title: "State Of NC Vs Johnson",
    variant: "warning",
    description: "The composition of the jury has been approved.",
    onClick: () => {
      window.location.href = routes.CREATE_CASE;
    },
    properties: [
      {
        property_name: "Court Case #",
        property_value: "122556",
        property_format: ""
      },
      {
        property_name: "Court Jurisdiction",
        property_value: "Appellate",
        property_format: ""
      },
      {
        property_name: "Client's Name",
        property_value: "Johnson",
        property_format: ""
      },
      {
        property_name: "Trial Date",
        property_value: "07/17/2024",
        property_format: ""
      }
    ]
  }
];
export const tabSection3: TaskButtonProps[] = [
  {
    title: "State Of NC Vs Johnson",
    variant: "success",
    description: "The composition of the jury has been approved.",
    onClick: () => {
      window.location.href = routes.CREATE_CASE;
    },
    properties: [
      {
        property_name: "Court Case #",
        property_value: "122556",
        property_format: ""
      },
      {
        property_name: "Court Jurisdiction",
        property_value: "Appellate",
        property_format: ""
      },
      {
        property_name: "Client's Name",
        property_value: "Johnson",
        property_format: ""
      },
      {
        property_name: "Trial Date",
        property_value: "07/17/2024",
        property_format: ""
      }
    ]
  }
];
export const tabSection4: TaskButtonProps[] = [
  {
    title: "State Of NC Vs Johnson",
    variant: "danger",
    description: "The composition of the jury has been approved.",
    onClick: () => {
      window.location.href = routes.CREATE_CASE;
    },
    properties: [
      {
        property_name: "Court Case #",
        property_value: "122556",
        property_format: ""
      },
      {
        property_name: "Court Jurisdiction",
        property_value: "Appellate",
        property_format: ""
      },
      {
        property_name: "Client's Name",
        property_value: "Johnson",
        property_format: ""
      },
      {
        property_name: "Trial Date",
        property_value: "07/17/2024",
        property_format: ""
      }
    ]
  }
];

export const tabSectionsList: TabSectionsPropTypes[] = [
  {
    id: 1,
    section: tabSection1
  },
  {
    id: 2,
    section: tabSection2
  },
  {
    id: 3,
    section: tabSection3
  },
  {
    id: 4,
    section: tabSection4
  }
];
