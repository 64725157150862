import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CaseListData } from "../../utils/types/index";
import { caseListGridData } from "../../utils/constants";
import { CasesState } from "../../utils/types/index";

const initialState: CasesState = {
  caseList: caseListGridData
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    addCase: (state, action: PayloadAction<CaseListData>) => {
      state.caseList = [action.payload, ...state.caseList];
    },
    deleteCase: (state, action: PayloadAction<string>) => {
      state.caseList = state.caseList.filter((caseItem) => caseItem.caseNumber !== action.payload);
    }
  }
});

export const { addCase, deleteCase } = casesSlice.actions;

export default casesSlice.reducer;
