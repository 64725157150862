import React, { useCallback, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BUTTON_TITLE } from "../../utils/constants";
import { AddFieldValueModalProps } from "../../utils/types";
import ActionButton from "../ActionButton";
import TextInput from "../TextInput";

export const AddFieldValueModal: React.FC<AddFieldValueModalProps> = ({
  isOpen,
  toggle,
  onSubmit,
  fieldLabel,
  fieldPlaceholder
}) => {
  const [name, setName] = useState("");

  const handleSubmit = useCallback(() => {
    onSubmit(name);
    setName("");
  }, [name, onSubmit]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{fieldLabel ? fieldLabel : "Add Field"}</ModalHeader>
      <ModalBody>
        <TextInput
          value={name}
          placeholder={fieldPlaceholder ? fieldPlaceholder : "Field Place holder"}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <ActionButton title={BUTTON_TITLE.SAVE} className='save-button' disabled={!name} onClick={handleSubmit} />
        <ActionButton title={BUTTON_TITLE.CANCEL} onClick={toggle} className='cancel-button' />
      </ModalFooter>
    </Modal>
  );
};
