import React from "react";
import { useFormik } from "formik";
import "../jurorEvaluation.scss";
import {
  BUTTON_TITLE,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  jurorEvaluationTabsData,
  navCards,
  TITLES
} from "../../../../../utils/constants";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import TextInput from "../../../../TextInput";
import ActionButton from "../../../../ActionButton";
import { basicIndicatorsInitialValues, basicIndicatorsValidationSchema } from "../helper";
import { JurorEvaluationProps } from "../../../../../utils/types";

const BasicIndicators: React.FC<JurorEvaluationProps> = ({ handleTabChange, setActiveCard }) => {
  const formik = useFormik({
    initialValues: basicIndicatorsInitialValues,
    validationSchema: basicIndicatorsValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });
  const { values, setFieldValue, resetForm, handleSubmit, handleChange } = formik;

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.BASIC_INDICATORS}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.OVERALL_JUROR_RATINGS}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.OVERALL_JUROR_RATINGS, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.OVERALL_JUROR_RATINGS_COMMENT, comment)}
              initialRating={values.overallJurorRatings}
              value={values.overallJurorRatingsComment}
            />
            <RatingField
              label={FORM_LABELS.LIKELIHOOD_OF_FAVORABILITY}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.LIKELIHOOD_OF_FAVORABILITY_COMMENT, comment)}
              initialRating={values.likelihoodOfFavorability}
              value={values.likelihoodOfFavorabilityComment}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.UNDERSTANDING_OF_LEGAL_CONCEPTS}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.UNDERSTANDING_OF_LEGAL_CONCEPTS_COMMENT, comment)}
              initialRating={values.understandingOfLegalConcepts}
              value={values.understandingOfLegalConceptsComment}
            />
            <RatingField
              label={FORM_LABELS.ATTITUDE_TOWARD_CASE}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.ATTITUDE_TOWARD_CASE, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.ATTITUDE_TOWARD_CASE_COMMENT, comment)}
              initialRating={values.attitudeTowardCase}
              value={values.attitudeTowardCaseComment}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.JUROR_BACKGROUND_KNOWLEDGE}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.JUROR_BACKGROUND_KNOWLEDGE_COMMENT, comment)}
              initialRating={values.jurorBackgroundKnowledge}
              value={values.jurorBackgroundKnowledgeComment}
            />
            <RatingField
              label={FORM_LABELS.COMMUNICATION_SKILLS}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.COMMUNICATION_SKILLS, value)}
              onCommentChange={(comment) => setFieldValue(FORM_NAMES.COMMUNICATION_SKILLS_COMMENT, comment)}
              initialRating={values.overallJurorRatings}
              value={values.overallJurorRatingsComment}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT}
              onRatingChange={(value) => setFieldValue(FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT, value)}
              onCommentChange={(comment) =>
                setFieldValue(FORM_NAMES.RECEPTIVITY_TO_ATTORNEYS_ARGUMENT_COMMENT, comment)
              }
              initialRating={values.communicationSkills}
              value={values.communicationSkillsComment}
            />
            <TextInput
              label={FORM_LABELS.ADDITIONAL_INFORMATION_OPTIONAL}
              name={FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL}
              type={FIELD_TYPES.TEXT_AREA}
              placeholder={FORM_PLACEHOLDERS.COMMENT_HERE}
              value={values[FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL]}
              onChange={handleChange}
            />
          </div>
          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[1]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                setActiveCard?.(navCards[0]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default BasicIndicators;
