import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ModalWrapperProps } from "../../utils/types";

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  showModal,
  showHeader = true,
  showFooter = false,
  headerTitle,
  footerComponent,
  onClose,
  size = "md", // Default size is medium
  children
}) => {
  return (
    <Modal
      isOpen={showModal}
      toggle={onClose}
      centered
      fullscreen={false} // Remove this or keep it based on your requirements
      size={size} // Accepts 'sm', 'md', 'lg', 'xl'
    >
      {showHeader && <ModalHeader toggle={onClose}>{headerTitle}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
      {showFooter && <ModalFooter>{footerComponent}</ModalFooter>}
    </Modal>
  );
};

export default ModalWrapper;
