import * as Yup from "yup";
import { PoliticalAndSocialFormValueProps } from "../../../../../utils/types";

export const validationSchema = Yup.object().shape({
  partyAffiliation: Yup.string().optional(),
  votingRecords: Yup.string().optional(),
  donorInformation: Yup.string().optional(),
  isDonorInformationAvailable: Yup.boolean().optional()
});

export const initialValues: PoliticalAndSocialFormValueProps = {
  partyAffiliation: "",
  votingRecords: "",
  donorInformation: "",
  isDonorInformationAvailable: false
};
