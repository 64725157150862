import React, { useCallback } from "react";
import { useFormik } from "formik";
import "../jurorProfile.scss";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  jurorProfileTabsData,
  navCards,
  TITLES
} from "../../../../../utils/constants";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import ActionButton from "../../../../ActionButton";
import { strengthsInitialValues, strengthsValidationSchema } from "../helper";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";

const Strengths: React.FC<JurorEvaluationProps> = ({ setActiveCard, handleTabChange }) => {
  const formik = useFormik({
    initialValues: strengthsInitialValues,
    validationSchema: strengthsValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.STRENGTHS}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.TECHNICAL_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.TECHNICAL_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.TECHNICAL_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.TECHNICAL_SKILLS] as RatingValue}
              value={values[FORM_NAMES.TECHNICAL_SKILLS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.SOFT_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.SOFT_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.SOFT_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.SOFT_SKILLS] as RatingValue}
              value={values[FORM_NAMES.SOFT_SKILLS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.LIFE_EXPERIENCES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.LIFE_EXPERIENCES, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.LIFE_EXPERIENCES_COMMENT, comment)}
              initialRating={values[FORM_NAMES.LIFE_EXPERIENCES] as RatingValue}
              value={values[FORM_NAMES.LIFE_EXPERIENCES_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.ACHIEVEMENTS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ACHIEVEMENTS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ACHIEVEMENTS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ACHIEVEMENTS] as RatingValue}
              value={values[FORM_NAMES.ACHIEVEMENTS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.LEADERSHIP_QUALITIES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.LEADERSHIP_QUALITIES, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.LEADERSHIP_QUALITIES_COMMENT, comment)}
              initialRating={values[FORM_NAMES.LEADERSHIP_QUALITIES] as RatingValue}
              value={values[FORM_NAMES.LEADERSHIP_QUALITIES_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.RESILIENCE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.RESILIENCE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.RESILIENCE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.RESILIENCE] as RatingValue}
              value={values[FORM_NAMES.RESILIENCE_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.COMMUNICATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.COMMUNICATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.COMMUNICATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.COMMUNICATION] as RatingValue}
              value={values[FORM_NAMES.COMMUNICATION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.ANALYTICAL_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ANALYTICAL_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ANALYTICAL_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ANALYTICAL_SKILLS] as RatingValue}
              value={values[FORM_NAMES.ANALYTICAL_SKILLS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.DECISION_MAKING}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.DECISION_MAKING, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.DECISION_MAKING_COMMENT, comment)}
              initialRating={values[FORM_NAMES.DECISION_MAKING] as RatingValue}
              value={values[FORM_NAMES.DECISION_MAKING_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.TEAMWORK}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.TEAMWORK, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.TEAMWORK_COMMENT, comment)}
              initialRating={values[FORM_NAMES.TEAMWORK] as RatingValue}
              value={values[FORM_NAMES.TEAMWORK_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                handleTabChange?.(jurorProfileTabsData[1]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                setActiveCard?.(navCards[1]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default Strengths;
