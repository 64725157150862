import React from "react";
import { NavigationTabProps, TabItem } from "../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircle, faCircleDashed } from "@fortawesome/pro-regular-svg-icons";
import "./style.navigationtabs.scss";

/**
 * A navigation tab component that displays a list of tabs with different statuses.
 * Each tab can be marked as "current", "completed", or inactive. Clicking on a tab
 * triggers the `onClick` handler provided in the `tabs` array.
 *
 * @component
 * @example
 * const tabs = [
 *   { label: "Tab 1", status: "current", onClick: () => {} },
 *   { label: "Tab 2", status: "completed", onClick: () => {} },
 *   { label: "Tab 3", status: "inactive", onClick: () => {} },
 * ];
 *
 * return <NavigationTab tabs={tabs} />;
 *
 * @param {NavigationTabProps} props - The properties for the component.
 * @param {TabItem[]} props.tabs - An array of tab items to display in the navigation.
 * @param {string} props.activeTab - The currently active tab identifier.
 * @param {function} props.setActiveTab - Function to set the currently active tab.
 * @returns {JSX.Element} The rendered navigation tab component.
 */
const NavigationTab: React.FC<NavigationTabProps> = ({ tabs, activeTab, setActiveTab }) => {
  const getTabIcon = (tab: TabItem) => {
    switch (tab.status) {
      case "completed":
        return <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#137c4e" }} />;
      case "current":
        return <FontAwesomeIcon icon={faCircle} className='color-primary-light' />;
      case "upcoming":
      default:
        return <FontAwesomeIcon icon={faCircleDashed} className='color-gray' />;
    }
  };
  return (
    <div className='navigation-tab'>
      {tabs.map((tab: TabItem, index: number) => (
        <div
          key={index}
          className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
          onClick={() => setActiveTab(tab.id)}
        >
          <span className='tab-label'>{tab.label}</span>

          <div className='tab-indicator'>{getTabIcon(tab)}</div>
        </div>
      ))}
    </div>
  );
};

export default NavigationTab;
