import React from "react";
import WrapperComponent from "../../../../WrapperComponent";
import { Formik } from "formik";
import { PersonalDataProps, PersonalPreferencesFormValueProps } from "../../../../../utils/types";
import { initialValues, validationSchema } from "./helper";
import { PersonalPreferencesForm } from "./PersonalPreferencesForm";

const PersonalPreferences: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title='Personal Preferences'>
      <Formik<PersonalPreferencesFormValueProps>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <PersonalPreferencesForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};

export default PersonalPreferences;
