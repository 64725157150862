import moment from "moment";
import { DocumentData } from "../types";

/**
 * Transforms the file object and additional data to the required document format.
 *
 * @param {File} file - The file object to transform.
 * @param {string} caseName - The name of the case associated with the document.
 * @param {string} clientName - The name of the client.
 * @param {string} addedBy - The name of the person or team who added the document.
 * @returns {DocumentData} The transformed document data.
 */
export const transformFileToDocumentData = (
  file: File,
  caseName: string,
  clientName: string,
  addedBy?: string
): DocumentData => {
  const { name } = file;

  const docType = name?.length ? name.substring(name.lastIndexOf(".")) : "";

  const currentDate = moment().format("MM/DD/YYYY");

  return {
    documentName: name,
    docType: docType,
    caseName: caseName,
    clientName: clientName,
    dateAdded: currentDate,
    addedBy: addedBy || "Team Anerva",
    actions: "actions"
  };
};
