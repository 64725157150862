import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { MenuItem, SidebarMenuCustomProps } from "./layout_types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SidebarMenuLinkCustom from "./SideBarMenuLinkCustom";

const SidebarMenuCustom: React.FC<SidebarMenuCustomProps> = ({ menu, index, collapse, onCollapse }) => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(collapse);

  const isActive = useMemo(
    () =>
      !!(location.pathname === menu.route || menu.children?.some((item: MenuItem) => location.pathname === item.route)),
    [location.pathname, menu.children, menu.route]
  );

  const hasChildren = useMemo(() => !!((menu?.children || [])?.length > 0), [menu.children]);

  useEffect(() => {
    setIsCollapsed(collapse);
  }, [collapse]);

  const handleToggleCollapse = useCallback(() => {
    const newCollapseState = !isCollapsed;
    setIsCollapsed(newCollapseState);
    onCollapse(newCollapseState ? undefined : menu);
  }, [isCollapsed, menu, onCollapse]);

  return (
    <li className={!isCollapsed ? "mm-active" : isActive ? "mm-active" : ""}>
      {menu.tooltip_en && (
        <UncontrolledTooltip placement='top' target={`tooltip-${index}`}>
          {menu.tooltip_en}
        </UncontrolledTooltip>
      )}
      <Link
        to={hasChildren && !menu.route ? "#" : menu.route!}
        className={`waves-effect ${!isCollapsed ? "mm-active" : isActive ? "mm-active" : ""}`}
        id={`tooltip-${index}`}
        onClick={handleToggleCollapse}
        style={{ justifyContent: "space-between" }}
      >
        <div className='menu-label'>
          {menu?.icon && <i className={menu?.icon || ""} />}
          <span style={{ marginLeft: "10px" }} className='font-size-15 menu-text'>
            {menu.title_en}
          </span>
        </div>

        {isCollapsed && <FontAwesomeIcon icon={faChevronRight} className='chevron-icon' />}
        {!isCollapsed && hasChildren && <FontAwesomeIcon icon={faChevronUp} className='chevron-icon' />}
      </Link>
      {hasChildren && (
        <ul className={`sub-menu mm-collapse ${isCollapsed ? "" : "mm-show"}`} aria-expanded='false'>
          {menu.children?.map((menuLink, i) => (
            <SidebarMenuLinkCustom
              key={`${index}-${i}`}
              link={menuLink}
              label={menuLink.subtitle_en || ""}
              index={`${index}-${i}`}
            />
          ))}
          <hr className='sub-menu-bottom-border' />
        </ul>
      )}
    </li>
  );
};

export default SidebarMenuCustom;
