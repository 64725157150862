import React from "react";
import { JSXIconProps } from "../../utils/types";

export const EmailSVG = (props: JSXIconProps) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width={28}
      height={28}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={28} height={28} rx={14} />
      <path d='M8 9.5C7.6875 9.52083 7.52083 9.6875 7.5 10V10.6875L12.9062 15.125C13.2396 15.375 13.6042 15.5 14 15.5C14.4167 15.5 14.7917 15.375 15.125 15.125L20.5 10.6875V10C20.4792 9.6875 20.3125 9.52083 20 9.5H8ZM7.5 12.625V18V12.625V18C7.52083 18.3125 7.6875 18.4792 8 18.5H20C20.3125 18.4792 20.4792 18.3125 20.5 18V12.625L16.0625 16.2812C15.4375 16.7604 14.75 17 14 17C13.25 17 12.5625 16.7604 11.9375 16.2812L7.5 12.625ZM6 10C6.02083 9.4375 6.21875 8.96875 6.59375 8.59375C6.96875 8.21875 7.4375 8.02083 8 8H20C20.5625 8.02083 21.0312 8.21875 21.4062 8.59375C21.7812 8.96875 21.9792 9.4375 22 10V18C21.9792 18.5625 21.7812 19.0312 21.4062 19.4062C21.0312 19.7812 20.5625 19.9792 20 20H8C7.4375 19.9792 6.96875 19.7812 6.59375 19.4062C6.21875 19.0312 6.02083 18.5625 6 18V10Z' />
    </svg>
  );
};
