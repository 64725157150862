import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { participantGridData, candidatesGridData, witnessesGridData, expertsGridData } from "../../utils/constants";
import { Participant, ParticipantsState } from "../../utils/types";

const initialState: ParticipantsState = {
  candidates: candidatesGridData,
  witnesses: witnessesGridData,
  participants: participantGridData,
  experts: expertsGridData
};

const participantsSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    addCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = [{ ...action.payload }, ...state.candidates];
    },
    deleteCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = state.candidates.filter((candidate) => candidate.email !== action.payload.email);
    },
    addWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = [{ ...action.payload }, ...state.witnesses];
    },
    deleteWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = state.witnesses.filter((witness) => witness.firstName !== action.payload.firstName);
    },
    addParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = [{ ...action.payload }, ...state.participants];
    },
    deleteParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = state.participants.filter(
        (participant) => participant.firstName !== action.payload.firstName
      );
    },
    addExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = [{ ...action.payload }, ...state.experts];
    },
    deleteExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = state.experts.filter((expert) => expert.firstName !== action.payload.firstName);
    }
  }
});

// Exporting the actions
export const {
  addCandidate,
  deleteCandidate,
  addWitness,
  deleteWitness,
  addParticipant,
  deleteParticipant,
  addExpert,
  deleteExpert
} = participantsSlice.actions;

// Exporting the reducer
export default participantsSlice.reducer;
