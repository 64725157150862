import React, { useCallback, useMemo, useState } from "react";
import {
  BUTTON_TITLE,
  CASE_TABS,
  editPhotoLabelMap,
  editSubHeaderMap,
  editTitleMap,
  FORM_LABELS,
  NEW_CLIENT_TABS
} from "../../utils/constants";
import TabButtons from "../../components/TabButtons";
import { Tab } from "../../utils/types";
import "./styles.scss";
import CreateNewClient from "../../components/Pages/CreateCase/Participants/CreateNewClient";
import NavigationWrapper from "../../components/NavigationWrapper";
import { ActionButton, BreadCrumbs } from "../../components";
import { AiSearch } from "../../assets/jsxIcons";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import routes from "../../utils/constants/routes";
import { useLocation } from "react-router-dom";

const EditNewClient: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab | undefined>(NEW_CLIENT_TABS[0]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "";
  const photoLabel = editPhotoLabelMap[pathSegment as keyof typeof editPhotoLabelMap] || FORM_LABELS.CANDIDATE_PHOTO;
  const subheader = editSubHeaderMap[pathSegment as keyof typeof editSubHeaderMap];
  const route = useLocation();
  const personData = route.state?.participantData;

  const handleTabChange = useCallback((tab: Tab) => {
    setActiveTab(tab);
  }, []);

  const renderActiveTabContent = useMemo(() => {
    switch (activeTab?.value) {
      case CASE_TABS.BASIC:
        return (
          <CreateNewClient
            handleTabChange={handleTabChange}
            activeTab={activeTab?.value}
            photoLabel={photoLabel}
            subheader={subheader}
            data={personData}
          />
        );
      case CASE_TABS.DEMOGRAPHICS:
        return <CreateNewClient activeTab={activeTab?.value} handleTabChange={handleTabChange} data={personData} />;
      case CASE_TABS.LEGAL:
        return <CreateNewClient activeTab={activeTab?.value} handleTabChange={handleTabChange} />;
      case CASE_TABS.SOCIAL_MEDIA:
        return <CreateNewClient activeTab={activeTab?.value} handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  }, [activeTab?.value, handleTabChange, personData, photoLabel, subheader]);

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Participants", url: location.pathname }
        ]}
      />
      <NavigationWrapper
        title={title}
        headerChild={
          <ActionButton
            className={"bg-blue-light"}
            onClick={() => {
              setShowModal(true);
            }}
            title={BUTTON_TITLE.AI_PROFILE_GENERATE}
            icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
          />
        }
      >
        <div className='scrollable-container'>
          <TabButtons
            tabs={NEW_CLIENT_TABS}
            activeTab={activeTab || NEW_CLIENT_TABS[0]}
            onTabChange={(tab) => {
              setActiveTab(tab);
            }}
          />
          {renderActiveTabContent}
        </div>
        <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
      </NavigationWrapper>
    </div>
  );
};

export default EditNewClient;
