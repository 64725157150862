import * as Yup from "yup";
import { PersonalPreferencesFormValueProps } from "../../../../../utils/types";

export const validationSchema = Yup.object().shape({
  interestsHobbies: Yup.string().optional(),
  favoriteBooksMoviesTvShows: Yup.string().optional(),
  musicPreferences: Yup.string().optional(),
  foodPreferences: Yup.string().optional(),
  travelPreferences: Yup.string().optional(),
  pets: Yup.string().optional(),
  outdoorActivities: Yup.string().optional(),
  charitableContributions: Yup.string().optional(),
  religiousAffiliations: Yup.string().optional(),
  organizationalAffiliations: Yup.string().optional()
});

export const initialValues: PersonalPreferencesFormValueProps = {
  interestsHobbies: "",
  favoriteBooksMoviesTvShows: "",
  musicPreferences: "",
  foodPreferences: "",
  travelPreferences: "",
  pets: "",
  outdoorActivities: "",
  charitableContributions: "",
  religiousAffiliations: "",
  organizationalAffiliations: ""
};
