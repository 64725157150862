import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { ReactComponent as ChevronDownIcon } from "../../assets/images/icons/chevron-down.svg";

import { ProfileMenuProps } from "./layout_types";

const ProfileMenu = ({ username, menuItems }: ProfileMenuProps): JSX.Element => {
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
        <DropdownToggle
          className='btn header-item waves-effect'
          id='page-header-user-dropdown d-flex align-items-center'
          tag='button'
        >
          <i className='fa fa-regular fa-circle-user font-size-24 d-xl-none' />
          <span className='username-ellipsis d-none d-xl-inline-block ms-3 me-2 font-size-15 font-weight-bold'>
            {username}
          </span>
          <ChevronDownIcon className='arrow-down' />
        </DropdownToggle>
        <DropdownMenu end>
          {menuItems?.map((menuItem, i) => {
            return menuItem.type == "Divider" ? (
              <div key={i} className='dropdown-divider'></div>
            ) : menuItem.type == "Link" && menuItem.link ? (
              <Link to={menuItem.link} className='dropdown-item' key={i} onClick={() => setMenu(!menu)}>
                <i className={menuItem.icon + " font-size-16 align-middle me-1"} />
                <span>{menuItem.text_en}</span>
              </Link>
            ) : (
              <div
                className='dropdown-item'
                key={i}
                onClick={() => {
                  setMenu(!menu);
                  if (menuItem.action && typeof menuItem.action === "function") {
                    menuItem.action();
                  }
                }}
              >
                <i className={menuItem.icon + " font-size-16 align-middle me-1"} />
                <span>{menuItem.text_en}</span>
              </div>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
