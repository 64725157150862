/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React from "react";
import LanguageDropdown from "./LanguageDropdown";
import ProfileMenu from "./ProfileMenu";
import { HeaderProps } from "./layout_types";

import { ReactComponent as BarsIcon } from "../../assets/images/icons/burger.svg";
import { ReactComponent as GearIcon } from "../../assets/images/icons/gear.svg";
import SearchInput from "./SearchInput";

const Header = ({
  leftSideBarType,
  setLeftSideBarType,
  menuItems,
  username,
  handleSetting,
  showGearIcon = true,
  showNotification = false,
  hideSearch = false,
  hideTranslate = false,
  handleSearch,
  handleNotification,
  notifCount,
  notificationIconType = "solid",
  searchPlaceholder = ""
}: HeaderProps) => {
  const toggleSidebar = () => {
    if (leftSideBarType === "menu-opened") {
      setLeftSideBarType("menu-closed");
    } else {
      setLeftSideBarType("menu-opened");
    }
  };
  return (
    <React.Fragment>
      <header id='page-topbar' data-testid='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex setting-icons'>
            <button
              type='button'
              onClick={() => toggleSidebar()}
              className='ms-1 btn btn-sm font-size-24 header-item waves-effect'
              id='vertical-menu-btn'
            >
              <BarsIcon />
            </button>
            {showGearIcon && (
              <button
                type='button'
                onClick={handleSetting}
                className='ms-1 btn btn-sm font-size-24 header-item waves-effect'
                id='gear-btn'
              >
                <GearIcon />
              </button>
            )}
          </div>
          <div className='d-flex profile-icons align-items-center flex-row'>
            {/* <form className='app-search' onSubmit={onSubmitForm}>
              {showSearchResultBox && (
                <SearchResultBox
                  options={suggestions}
                  clearInput={clearInput}
                  setShowSearchResultBox={() => {
                    setShowSearchResultBox(true)
                  }}
                />
              )}
            </form> */}
            {!hideSearch && <SearchInput handleSearch={handleSearch} searchPlaceholder={searchPlaceholder} />}
            <div className='d-flex align-items-center flex-row'>
              {!hideTranslate && (
                <div className='language-dropdown'>
                  <LanguageDropdown />
                </div>
              )}
              {showNotification && (
                <div className='header-notification' onClick={handleNotification}>
                  {notifCount && notifCount > 0 && (
                    <div className='notification-count'>
                      <span>{notifCount}</span>
                    </div>
                  )}
                  <i className={`fa-${notificationIconType} fa-bell fa-xl custom-bell`}></i>
                </div>
              )}
              <ProfileMenu username={username} menuItems={menuItems} />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
