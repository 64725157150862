/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react";
import AsyncSelect from "react-select/async";
import { SingleValue, components } from "react-select";
import { selectStyle } from "./styles";
import "./styles.input.scss";
import { AutoCompleteInputProps, IListItem } from "../../utils/types";

const AutoCompleteInput = ({
  options = [],
  optionText,
  placeholder,
  onChangeValue,
  size = 5000,
  styles = selectStyle,
  label = "",
  iconType,
  required = false,
  inputValue,
  errorMessage = ""
}: AutoCompleteInputProps) => {
  const [selectedValue, setSelectedValue] = useState<IListItem | null>(null);

  const filterOptions = useCallback(
    (inputValue: string) => {
      if (!inputValue) {
        return options;
      }

      const filtered = options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));

      return filtered.slice(0, size);
    },
    [options, size]
  );

  const loadOptions = useCallback(
    async (inputValue: string) => {
      return filterOptions(inputValue);
    },
    [filterOptions]
  );

  const handleValueChange = useCallback(
    (value: SingleValue<IListItem>) => {
      setSelectedValue(value);
      onChangeValue(value);
    },
    [onChangeValue]
  );

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className={`fa-solid ${iconType === "search" ? "fa-magnifying-glass" : "fa-chevron-down"} icon`} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className='w-100'>
      {label && (
        <label className='autoComplete'>
          {label}
          {required && <span className='required-marker'> *</span>}
        </label>
      )}
      <AsyncSelect
        styles={styles}
        cacheOptions
        defaultOptions={options}
        value={selectedValue}
        loadOptions={loadOptions}
        placeholder={placeholder || `Filter by ${optionText}(s)`}
        onChange={handleValueChange}
        isSearchable
        isClearable
        inputValue={inputValue}
        components={{
          DropdownIndicator
        }}
      />
      <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
    </div>
  );
};

export default AutoCompleteInput;
