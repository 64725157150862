/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import SidebarMenuLink from "./SidebarMenuLink";
import "./styles.scss";

import { MenuItem, SidebarProps } from "./layout_types";
import { VersionNumber } from "./VersionNumber";
import SidebarMenuCustom from "./SidebarMenuCustom";

/** TODO: Replace logoImage with WorkflowUser.GetCurrentProject().GetLogoDark() later */

const SidebarContent = ({
  menuItems,
  logoImage,
  logoSmImage,
  hasDropdownMenu,
  dropdownChangeHandler,
  dropdownValue,
  dropdownOptions,
  showWorldClock,
  versionNumber,
  env
}: SidebarProps) => {
  const [openedMenu, setOpenedMenu] = useState<MenuItem | undefined>(undefined);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    menuItems.map((menu) => {
      if (menu.item_type == "Menu" || menu.item_type == "Custom") {
        menu.children?.map((item) => {
          // Set parent menu open when we are on its sub page
          if (item.route == location.pathname) {
            setOpenedMenu(menu);
          }
        });
      }
    });
  }, [menuItems]);
  return (
    <React.Fragment>
      <div id='sidebar-menu' style={{ height: showWorldClock ? "calc(100vh - 175px)" : "100vh" }}>
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logoSmImage} alt='anerva' height='44' />
            </span>
            <span className='logo-lg'>
              <img src={logoImage} alt='anerva' height='60' />
            </span>
          </Link>
        </div>
        {hasDropdownMenu && dropdownChangeHandler && (
          <select
            // placeholder={dropdownPlaceholder}
            onChange={(e) => dropdownChangeHandler(e.target.value)}
            value={dropdownValue ?? undefined}
            className='form-select form-select-dropdown mb-3'
          >
            {dropdownOptions?.map((item, i) => <option key={"dropdown" + i}>{item}</option>)}
          </select>
        )}
        <ul className='metismenu list-unstyled mm-active' id='side-menu'>
          {menuItems.map((item, i) =>
            item.item_type === "Link" ? (
              <SidebarMenuLink key={i} link={item} label={item.title_en || ""} index={`${i}-0`} />
            ) : item.item_type === "Label" ? (
              <span key={i} className='module-label'>
                {item.title_en}
              </span>
            ) : item.item_type === "Custom" ? (
              <SidebarMenuCustom
                key={i}
                menu={item}
                index={i}
                collapse={item !== openedMenu}
                onCollapse={(menu) => setOpenedMenu(menu)}
              />
            ) : (
              <SidebarMenu
                key={i}
                menu={item}
                index={i}
                collapse={item !== openedMenu}
                onCollapse={(menu) => setOpenedMenu(menu)}
              />
            )
          )}
        </ul>

        {versionNumber && <VersionNumber versionNumber={versionNumber} env={env} />}
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
