import React from "react";
import WrapperComponent from "../../components/WrapperComponent";
import { Formik } from "formik";
import { EvidenceFormValues } from "../../utils/types";
import { initialValues, validationSchema } from "./helper";
import NavigationWrapper from "../../components/NavigationWrapper";
import { BreadCrumbs } from "../../components";
import CaseEvidenceForm from "./CaseEvidenceForm";
import { TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";

const CaseEvidence = () => {
  return (
    <div>
      <BreadCrumbs
        breadcrumbItems={[
          { url: routes.MANAGE_CASE, text: "Manage Case" },
          { text: "Case Evidence", url: routes.CASE_EVIDENCE }
        ]}
      />
      <NavigationWrapper title={TITLES.CASE_EVIDENCE}>
        <WrapperComponent>
          <Formik<EvidenceFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              // Handle form submission here
            }}
          >
            <CaseEvidenceForm />
          </Formik>
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default CaseEvidence;
