import React from "react";
import "./navigationwrapper.scss";
import { NavigationWrapperProps } from "../../utils/types";
import { useNavigate } from "react-router-dom";

const NavigationWrapper: React.FC<NavigationWrapperProps> = ({
  title,
  titleClass,
  children,
  headerChild,
  goBack = true
}) => {
  const navigation = useNavigate();

  const onBack = () => {
    navigation(-1);
  };

  return (
    <div className='navigation-wrapper'>
      <div className={`nav-header ${headerChild ? "mb-20" : "mb-20"}`}>
        <div className='back-btn-container'>
          {goBack && (
            <span className='nav-back' onClick={onBack}>
              <i className='fas fa-arrow-left back-btn'></i>
            </span>
          )}
          <span className={`nav-title ${titleClass}`}>{title}</span>
        </div>
        {headerChild}
      </div>
      <div className='nav-content'>{children}</div>
    </div>
  );
};

export default NavigationWrapper;
