import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CaseEvidence } from "../../utils/types";
import { caseEvidenceGridData } from "../../utils/constants";
import { DocumentState } from "../types";

const initialState: DocumentState = {
  documents: caseEvidenceGridData,
  evidenceDocs: caseEvidenceGridData
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    // Document actions
    addDocument: (state, action: PayloadAction<CaseEvidence>) => {
      state.documents.unshift(action.payload);
    },
    deleteDocument: (state, action: PayloadAction<number>) => {
      state.documents = state.documents.filter((doc) => doc.id !== action.payload);
    },
    setDocuments: (state, action: PayloadAction<CaseEvidence[]>) => {
      state.documents = action.payload;
    },

    // Evidence document actions
    addEvidenceDocument: (state, action: PayloadAction<CaseEvidence>) => {
      state.evidenceDocs.unshift(action.payload);
    },
    deleteEvidenceDocument: (state, action: PayloadAction<number>) => {
      state.evidenceDocs = state.evidenceDocs.filter((doc) => doc.id !== action.payload);
    },
    setEvidenceDocuments: (state, action: PayloadAction<CaseEvidence[]>) => {
      state.evidenceDocs = action.payload;
    }
  }
});

export const {
  addDocument,
  deleteDocument,
  setDocuments,
  addEvidenceDocument,
  deleteEvidenceDocument,
  setEvidenceDocuments
} = documentsSlice.actions;

export default documentsSlice.reducer;
