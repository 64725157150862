import React from "react";
import WrapperComponent from "../../../../WrapperComponent";
import { Formik } from "formik";
import { initialSocialMediaValues, socialMediaValidationSchema } from "./helper";
import { SmAndOnlinePresenceForm } from "./SmAndOnlinePresenseForm";
import { PersonalDataProps } from "../../../../../utils/types";

export const SMAndOnlinePresence: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title={"Social Media & Online Presence"}>
      <Formik
        initialValues={initialSocialMediaValues}
        validationSchema={socialMediaValidationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <SmAndOnlinePresenceForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};
