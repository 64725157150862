import React from "react";
import { TITLES } from "../../utils/constants";
import { BreadCrumbs } from "../../components";
import NavigationWrapper from "../../components/NavigationWrapper";
import routes from "../../utils/constants/routes";
import WrapperComponent from "../../components/WrapperComponent";
import { AddNewCase } from "../../components/Pages/CreateCase";

const CreateCase: React.FC = () => {
  return (
    <div className='scrollable-container'>
      <BreadCrumbs breadcrumbItems={[{ text: "Create New Case", url: routes.CREATE_CASE }]} />
      <NavigationWrapper
        title={TITLES.CREATE_NEW_CASE}
        goBack={false}
        titleClass='color-primary-dark font-size-18 fw-bold'
      >
        <WrapperComponent>
          <AddNewCase />
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default CreateCase;
