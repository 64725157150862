import { Formik } from "formik";
import React from "react";
import { JurorInformationFormValues, PersonalDataProps } from "../../../../../utils/types";
import BasicInformationForm from "./BasicInformationForm";
import { initialValues, validationSchema } from "./helper";
import WrapperComponent from "../../../../WrapperComponent";

const BasicInformation: React.FC<PersonalDataProps> = ({ handleTabChange, setActiveCard }) => {
  return (
    <WrapperComponent title='Basic Information'>
      <Formik<JurorInformationFormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <BasicInformationForm setActiveCard={setActiveCard} handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};

export default BasicInformation;
