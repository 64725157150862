import { Formik } from "formik";
import { LegalRecordsValues, PersonalDataProps } from "../../../../../utils/types";
import WrapperComponent from "../../../../WrapperComponent";
import { LegalRecordsForm } from "./LegalRecordsForm";
import { initialValues, validationSchema } from "./helper";

const LegalRecordsInformation: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  return (
    <WrapperComponent title='Legal Records'>
      <Formik<LegalRecordsValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <LegalRecordsForm handleTabChange={handleTabChange} />
      </Formik>
    </WrapperComponent>
  );
};
export default LegalRecordsInformation;
