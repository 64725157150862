import React, { useState, useCallback } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./strikeJuror.scss";
import ActionButton from "../ActionButton";
import { BUTTON_TITLE, FIELD_TYPES, FORM_PLACEHOLDERS, STRIKE_JUROR_REASONS } from "../../utils/constants";
import TextInput from "../TextInput";
import { SelectedReasons, StrikeJurorModalProps } from "../../utils/types";

const StrikeJurorModal: React.FC<StrikeJurorModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [selectedReasons, setSelectedReasons] = useState<SelectedReasons>({
    cause: false,
    peremptory: false,
    biasParty: false,
    biasSubject: false,
    excused: false,
    other: false
  });

  const [otherReason, setOtherReason] = useState<string>("");

  const handleCheckboxChange = useCallback((name: keyof SelectedReasons) => {
    setSelectedReasons((prev) => ({
      ...prev,
      [name]: !prev[name]
    }));
  }, []);

  const handleOtherReasonChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherReason(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    const dataToSubmit = {
      selectedReasons,
      otherReason: selectedReasons.other ? otherReason : undefined
    };
    onSubmit(dataToSubmit);
    onClose();
    setSelectedReasons({
      cause: false,
      peremptory: false,
      biasParty: false,
      biasSubject: false,
      excused: false,
      other: false
    });
    setOtherReason("");
  }, [selectedReasons, otherReason, onSubmit, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        onClose();
        setSelectedReasons({
          cause: false,
          peremptory: false,
          biasParty: false,
          biasSubject: false,
          excused: false,
          other: false
        });
        setOtherReason("");
      }}
      className='strike-juror-modal'
    >
      <ModalBody>
        <div className='strike-juror-modal-content'>
          <p className='strike-juror-modal-title'>Strike Juror</p>
          <p className='juror-subtitle'>Reason for striking Juror?</p>

          <div className='strike-juror-checkbox-group'>
            {STRIKE_JUROR_REASONS.map(({ name, label }) => (
              <div
                key={name}
                className={`custom-checkbox ${selectedReasons[name as keyof SelectedReasons] ? "checked" : ""}`}
                onClick={() => handleCheckboxChange(name as keyof SelectedReasons)}
              >
                <span>{label}</span>
                <div
                  className={`checkbox-indicator ${selectedReasons[name as keyof SelectedReasons] ? "checked" : ""}`}
                />
              </div>
            ))}

            <TextInput
              placeholder={FORM_PLACEHOLDERS.REASON_HERE}
              value={otherReason}
              type={FIELD_TYPES.TEXT_AREA}
              onChange={handleOtherReasonChange}
            />
          </div>

          <div className='strike-juror-modal-buttons'>
            <ActionButton className='save-button padding-x' onClick={handleSubmit} title={BUTTON_TITLE.APPLY} />

            <ActionButton className='cancel-button padding-x' onClick={onClose} title={BUTTON_TITLE.CANCEL} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StrikeJurorModal;
