// helper.ts

import * as Yup from "yup";
import { DemographicsFormValueProps } from "../../../../../utils/types";

export const demographicsValidationSchema = Yup.object().shape({
  ethnicity: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  economicStatus: Yup.string().optional(),
  workplace: Yup.string().optional(),
  academic_records: Yup.string().optional(),
  estimatedHouseholdIncome: Yup.number().min(0, "Income must be a positive number").optional(),
  estimatedNetWorth: Yup.number().min(0, "Net Worth must be a positive number").optional(),
  lengthOfResidence: Yup.string().optional(),
  homeOwnershipStatus: Yup.string().optional()
});

export const demographicsInitialValues: DemographicsFormValueProps = {
  ethnicity: "",
  occupation: "",
  educationLevel: "",
  economicStatus: "",
  workplace: "",
  academic_records: "",
  estimatedHouseholdIncome: 0,
  estimatedNetWorth: 0,
  lengthOfResidence: "",
  homeOwnershipStatus: ""
};
